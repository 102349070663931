import React, { useState, useEffect } from 'react'
import { Typography, Row, Col, Steps, Divider, message, Spin } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import moment from 'moment';
import style from './quizStepOption.module.scss';


const OptionIcon = ({ filled = false, onClick }) => {

    const handleOnClick = () => {
        // handle click
        console.log("Option clicked!!")
        onClick()
    }
    return (
        <svg className={style.stepIcon} style={{ zIndex: '1', position: 'relative' }}
            xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" onClick={handleOnClick}>
            <path d="M34.1958 17.875C34.7763 18.8806 34.7763 20.1194 34.1958 21.125L28.2552 31.4144C27.6746 32.42 26.6017 33.0394 25.4406 33.0394L13.5594 33.0394C12.3983 33.0394 11.3254 32.42 10.7448 31.4144L4.80422 21.125C4.22366 20.1194 4.22366 18.8806 4.80422 17.875L10.7448 7.58558C11.3254 6.58003 12.3983 5.96058 13.5594 5.96058L25.4406 5.96058C26.6017 5.96058 27.6746 6.58003 28.2552 7.58558L34.1958 17.875Z" fill={filled ? '#2E5ACC' : 'white'} stroke="#2D2A26" stroke-width="1.5" />
        </svg>
    )
}

const QuizStepOption = ({ options, value, onChange }) => {

    let verticalLines = {
        icon: (<Divider className={style.verticalDivider} type="vertical" />),
        description: ""
    };
    let horizontalLines = {
        icon: (<Divider className={style.horizontalDivider} type="horizontal" />),
        description: ""
    };
    let statusIconObj = {
        icon: (<OptionIcon />),
        description: "",
        className: "status-icon-container"
    };

    const arrowLeft = {
        icon: (<div className={`${style.arrow} ${style.arrowLeft}`} />),
        description: ''
    };

    const arrowRight = {
        icon: (<div className={`${style.arrow} ${style.arrowRight}`} />),
        description: ''
    };

    function itemsBuilder(iconsList, forHorizontalTracker) {
        let length = iconsList.length + ((iconsList.length + 1));
        let trackerLines = horizontalLines;
        if (forHorizontalTracker) {
            // length = ((iconsList.length + 1) * 2) + iconsList.length;
            trackerLines = verticalLines;
        }
        return Array.from({ length }, itemsBuilderMapper.bind(this, iconsList, forHorizontalTracker, trackerLines));
    }

    function itemsBuilderMapper(iconsList, forHorizontalTracker, trackerLines, _, i) {
        let next = (i + 1),
            iconIdx = (next / 2) - 1,
            setIcon = (next % 2 === 0);

        if (i === 0) {
            return { ...arrowLeft }
        }
        let length = iconsList.length + ((iconsList.length + 1));
        if (i === length - 1) {
            return { ...arrowRight }
        }
        if (setIcon) {
            const completed = true
            const { src, incomplete, description } = { ...iconsList[iconIdx] };
            let iconObj = { ...statusIconObj };
            // iconObj.icon = (<img alt='' className={style.stepIcon} src={src} />);
            if (value === description) {
                iconObj.icon = (<OptionIcon filled={true} onClick={() => onChange('')} />);
            } else {
                iconObj.icon = (<OptionIcon onClick={() => onChange(description)} />);
            }


            iconObj.description = description;
            if (iconIdx === 0) {
                iconObj.className += ` first-icon`;
            }
            return { ...iconObj };
        }
        if ((i > 0 && i < length - 2)) {
            return { ...trackerLines, icon: <></> };
        }
        return { ...trackerLines };
    }

    return (
        <div className={style.outerContainer}>
            <div className={style.statusContentWrapper}>
                <div className={style.statusContent}>
                    <div className='horizontalStepQuizOption'>
                        <Steps labelPlacement='vertical' responsive={false} current={0} className={style.step}
                            items={itemsBuilder(options, true)}>
                        </Steps>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default QuizStepOption;