import React, { useState } from 'react'
import { Button } from 'antd';
import { getAllQuizData } from '../AdminPortalAPIService';
import { handleErrorMsg } from '@ph/common/global/Helpers';

function QuizList({ id }) {
    const [loading, setLoading] = useState(false)

    const generateFileName = () => {
        const now = new Date()
        const formattedDate = now.toISOString().split('T')[0]
        const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '')

        return `PH_quiz_data_${formattedDate}_${formattedTime}.csv`
    }

    const handleDownloadReport = (inputData) => {
        const reportData = inputData.quiz_data;

        // Function to safely convert a cell to CSV-friendly string
        const convertToCSVCell = (cell) => {
            if (cell === null || cell === undefined) {
                return '';
            }
            if (typeof cell === 'object') {
                return '"' + JSON.stringify(cell).replace(/"/g, '""') + '"';
            }
            if (typeof cell === 'string') {
                return '"' + cell.replace(/"/g, '""') + '"';
            }
            return cell;
        };

        // Convert data to CSV string
        const csvData = reportData.map(row =>
            row.map(convertToCSVCell).join(',')
        ).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, generateFileName());
        } else {
            link.href = URL.createObjectURL(blob);
            link.download = generateFileName();
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleButtonClick = () => {
        setLoading(true)
        getAllQuizData().then(res => {
            if (res && res.data) {
                handleDownloadReport(res.data)
            }
        })
            .catch(error => handleErrorMsg(error, "Unable to fetch quiz data"))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Button loading={loading} onClick={handleButtonClick}> Download CSV</Button>
        </>
    );
}

export default QuizList;