import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import style from './areaButton.module.scss';

const AreaButton = ({ area, isSelected, onClick, backgroundColor, children, color }) => {
    const styles = {
        '--text-color': color,
        '--bg-color': backgroundColor,
    }
    return (
        <Button
            className={`${style.areaButton} ${isSelected ? style.selected : ''}`}
            style={styles}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

AreaButton.propTypes = {
    area: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
    children: PropTypes.node.isRequired,
};

AreaButton.defaultProps = {
    backgroundColor: '#FFFFFF',
    color: "#000"
};

export default AreaButton;
