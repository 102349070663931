import { useState } from 'react';
import style from "../PHProtocol/PHProtocol.module.scss";
import { Typography } from 'antd';

const { Paragraph } = Typography;

function QuizReadMoreDescContainer({ description, rowCount = 4, productId = 1, productName = '', className = '', readMoreColor = "#767472" }) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [fullDescriptionKey, setFullDescriptionKey] = useState(productId);

    const markup = typeof description === 'object' && description.length
        ? description.flatMap((part, index) =>
            index === description.length - 1
                ? part
                : [part, <b key={index}>{productName}</b>]
        )
        : [description];

    const toggleFullProdDescHandler = (showOrHide) => {
        if (typeof showOrHide === 'boolean') {
            setShowFullDescription(showOrHide);
        } else {
            setShowFullDescription(!showFullDescription);
        }
        setFullDescriptionKey(fullDescriptionKey + (!showFullDescription ? 0 : 1));
    };

    return (
        <>
            <div key={fullDescriptionKey} className={`${style.productDesc} ${className}`}>
                <Paragraph className={`${style.productDesc} ${className}`}
                    ellipsis={{
                        rows: rowCount,
                        expandable: true,
                        symbol: <span style={{ display: 'block', marginLeft: '0px', color: readMoreColor, textDecoration: 'underline' }} className='note-2 font-Mono-medium'>READ MORE</span>,
                        onExpand: () => toggleFullProdDescHandler(true)
                    }}
                >
                    {description}
                </Paragraph>
                {showFullDescription &&
                    <span style={{ display: 'block', marginLeft: '0px', color: readMoreColor, textDecoration: 'underline' }} className='note-2 font-Mono-medium hand-cursor' onClick={() => toggleFullProdDescHandler(false)}>READ LESS</span>
                }
            </div>

        </>
    );
}


export default QuizReadMoreDescContainer;