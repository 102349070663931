import React, { useEffect, useRef, useState } from 'react'
import AreaButton from './AreaButton'
import { Button, Input } from 'antd';
import style from './areaButton.module.scss';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants';



const OTHER_BUTTON_CONFIG = {
    area: 'Other',
    text: 'Other',
    backgroundColor: '#BAB9B8',
    color: '#000000',
}
const OTHER_BUTTON = 'Other'


function AreaButtonWrapper({ options, values, onChange, otherAllowed = false }) {

    const finalOptions = otherAllowed ? [...options, OTHER_BUTTON_CONFIG] : options;

    const isIncludedArea = (area) => {
        return values?.includes(area)
    }

    const handleOnChange = (newValue) => {
        let valuesCopy = values
        if (!Array.isArray(valuesCopy))
            valuesCopy = []

        if (newValue == null) return;

        if (newValue === NO_ISSUES_TEXT) {
            // Add No issues
            if (values.includes(NO_ISSUES_TEXT)) {
                onChange([])
            } else {
                onChange([newValue])
            }
            return;
        }

        if (newValue === '' || newValue === OTHER_BUTTON) {
            const filterArray = values?.filter(item => isAlreadyPresented(item));
            onChange(filterArray)
            return
        }
        let updatedValues = []

        if (isAlreadyPresented(newValue)) {
            updatedValues = valuesCopy.includes(newValue)
                ? valuesCopy.filter(item => item !== newValue)
                : [...valuesCopy, newValue];
        } else {
            const filerArray = values?.filter(item => isAlreadyPresented(item));
            if (filerArray) {
                updatedValues = [...filerArray, newValue]

            } else {
                updatedValues = [newValue]
            }
        }
        const finalValues = updatedValues.filter(item => item !== NO_ISSUES_TEXT)
        onChange(finalValues);
    };

    const getOtherValue = () => {
        const difference = values.filter(item => !isAlreadyPresented(item));
        if (difference && difference.length > 0) {
            return difference[0]
        }
        return ''
    }

    const isAlreadyPresented = (newValue) => {
        return options.filter(area => area.area === newValue).length > 0
    }

    return finalOptions.map((config) => (
        config.area === 'Other' ? <OtherButton value={getOtherValue()} isSelected={getOtherValue()} handleOnChange={handleOnChange} />
            :
            <AreaButton
                key={config.area} // Use a unique key for each button
                area={config.area}
                isSelected={isIncludedArea(config.area)}
                onClick={() => handleOnChange(config.area)}
                backgroundColor={config.backgroundColor}
                color={config.color}
            >
                {config.text}
            </AreaButton>
    ))


}

export default AreaButtonWrapper

const OtherButton = ({ value, isSelected, handleOnChange }) => {
    const [inputValue, setInputValue] = useState(value ? value : OTHER_BUTTON)
    const inputRef = useRef(null)
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (isFocused) {
            inputRef.current.focus();
        }
    }, [isFocused]);

    const handleFocus = () => {
        setIsFocused(true);
        if (inputValue === OTHER_BUTTON) {
            setInputValue('')
        }
    };

    const handleOtherClick = (e) => {
        inputRef?.current?.focus();
    }


    const handleInputBlur = () => {
        if (!inputValue && !inputValue?.trim() || inputValue?.trim() === OTHER_BUTTON) {
            setInputValue(OTHER_BUTTON)
        }
        handleOnChange(inputValue)
        setIsFocused(false);
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            handleInputBlur()
        }
    }

    return (
        <div onClick={handleOtherClick} className={`${style.areaButton} ${style.otherButton} ${isSelected ? style.selected : ''}`} title={inputValue}>
            <Input
                ref={inputRef}
                type='text'
                value={inputValue}
                className={style.otherInput}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputBlur}
                onFocus={handleFocus}
                onKeyDown={handleEnterKey}
                allowClear={isFocused ? true : false}
            />
        </div>
    )
}