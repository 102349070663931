import { useEffect, useState } from 'react';
import style from '../../AdminLabPortal/ValidateKitFormContent/ValidateKitFormContent.module.scss';
import { Button, Col, Form, Row, Typography } from "antd";
import { close_icon } from "@ph/common/assets";
import Components from "@ph/common/components";
import { postAssignKitcode } from '../ServiceDashboardAPIService';
import { handleErrorMsg, handleSuccessMsg } from '@ph/common/global/Helpers';

const { Text } = Typography

function UnassignedOrdersForm({
    title, record, kitList, closeClickHandler, onValidationStatusChanged }) {

    const [kitTypeInventory,setKitTypeInventory] = useState([])

    const getLineItmesEmails = () => {
        const lineItems = record?.gifting_info
        const resultEmails = []
        if (lineItems) {
            lineItems.map(item => {
                const firstName = item?.gifting_to_first_name?.trim()
                const lastName = item?.gifting_to_last_name?.trim()
                const email = item?.gifting_to_email?.trim()
                const namePart = firstName || lastName ? `(${firstName ? firstName : ''} ${lastName ? lastName : ''})`.trim() : '';
                if (email) {
                    resultEmails.push({
                        value: item?.gifting_to_email,
                        label: `${item?.gifting_to_email} ${namePart}`
                    });
                }
            });
        }

        return resultEmails
    }

    const getDetailsByEmail = (email) => {
        const lineItems = record?.gifting_info
        const resultEmailDetails = lineItems.filter(item => item.gifting_to_email === email)
        return resultEmailDetails.length > 0 ? resultEmailDetails[0] : {}
    }

    const updateKitInventory = () => {
        const availabeKitInventory = record?.line_item_info
        if (availabeKitInventory){
            const resultInventory = []
            availabeKitInventory.map(kit => {
                resultInventory.push({ ...kit, selectedKit: kit.assigned_kitcode, selectedEmail: kit.gifting_to_email, dirty: false })
            })
            setKitTypeInventory(resultInventory)
        }

    }

    useEffect(() => {
        updateKitInventory()
    }, [record]);


    const handleKitcodeHandler = (value, id) => {
        setKitTypeInventory(prevState => {
            return prevState.map(item => {
                if (item.line_item_uid === id) {
                    return { ...item, selectedKit: value, dirty: true };
                }
                return item;
            });
        });
    };

    const handleKitEmailHandler = (value, id) => {
        setKitTypeInventory(prevState => {
            return prevState.map(item => {
                if (item.line_item_uid === id) {
                    return { ...item, selectedEmail: value, dirty: true };
                }
                return item;
            });
        });
    };

    const handleSave = () => {
        const promises = [];

        kitTypeInventory.forEach(kit => {
            const emailDetails = getDetailsByEmail(kit.selectedEmail)
            const requestBody = {
                kitcode: kit.selectedKit,
                order_id: record.order_id,
                line_item_uid: kit.line_item_uid,
                gifting_to_first_name: emailDetails?.gifting_to_first_name ?? '',
                gifting_to_last_name: emailDetails?.gifting_to_last_name ?? '',
                gifting_to_email: kit?.selectedEmail
            };

            const formData = new FormData();

            for (let key in requestBody) {
                if (requestBody.hasOwnProperty(key)) {
                    formData.append(key, requestBody[key]);
                }
            }

            if (kit.selectedKit && kit.dirty) {
                // Pushing the promise returned by postAssignKitcode into the promises array
                promises.push(postAssignKitcode(formData)
                    .then(res => {
                        handleSuccessMsg(`Kit ${kit.selectedKit} assigned successfully!`);
                    })
                    .catch(error => {
                        handleErrorMsg(error,"Unable to assign kitcode!")
                    }));
            }
        });

        Promise.all(promises)
            .then(() => {
                onValidationStatusChanged();
            })
            .catch(error => {
                console.error('Error occurred:', error);
            });
    };



    return (
        <>
            <div className={style.expandedRow}>
                <div className={style.expandedRowArrow}>
                    <div className={style.expandedRowArrowTR}></div>
                </div>
                <Row>
                    <Col span={12}>{title}</Col>
                    <Col span={12} className='text-right'>
                        <img className={`${style.closeIcon} hand-cursor`} src={close_icon} alt='close'
                            onClick={closeClickHandler} />
                    </Col>
                </Row>
                <Form>
                    <Row className={style.validateFormContainer}>
                    </Row>
                    <Form.Item>
                        <Row>
                            <Col span={6}> <Typography className="text-light" >PURCHASER EMAIL ADDRESS</Typography></Col>
                            <Col span={6}> <Typography className="text-light">{record?.purchaser_email}</Typography></Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        {kitTypeInventory.map(item =>
                            <Row gutter={[0, 20]} align="middle" style={{ marginBottom: 40 }} key={item.line_item_uid}>
                                <Col span={6} className="text-light body-txt-lg">{item.kit_type_id} : {item.kit_type_name}</Col>
                                <Col span={7}>
                                    <Components.CustomSelect
                                        placeholder="Enter Kit code"
                                        showSearch={true}
                                        dark={true}
                                        options={kitList[item.kit_type_id]}
                                        onChangeHandler={(value) => handleKitcodeHandler(value, item.line_item_uid)}
                                        selected={item.selectedKit}
                                    />
                                </Col>
                                <Col span={8} style={{ marginLeft: 40 }}>
                                    <Components.CustomSelect
                                        placeholder="Select Email"
                                        showSearch={false}
                                        options={getLineItmesEmails()}
                                        dark={true}
                                        onChangeHandler={(value) => handleKitEmailHandler(value, item.line_item_uid)}
                                        selected={item.selectedEmail}
                                    />
                                </Col>
                        </Row>
                        )}
                    </Form.Item>
                    <Form.Item className="text-center">
                        <Button type="secondary" onClick={handleSave}>SAVE</Button>
                    </Form.Item>

                </Form>
            </div>

        </>
    );
}

export default UnassignedOrdersForm;