import React, { useState, useRef, useEffect } from 'react';
import { Select, Input, Grid } from 'antd';
import style from './quizSelect.module.scss'

const { Option } = Select;
const { useBreakpoint } = Grid;

const DownArrow = () => {
    return (
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.56201L10.062 10.124L18.624 1.56201" stroke="#ABBDEA" stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

const CustomSelect = ({ options = [], onChange, className = '', value }) => {
    const [selectValue, setValue] = useState(value ? value : '');
    const [otherValue, setOtherValue] = useState('');
    const [isInputVisible, setIsInputVisible] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef(null);
    const selectRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const screens = useBreakpoint();
    const isMobile = screens.xs

    const handleDropdownVisibleChange = (open) => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        if (isInputVisible) {
            inputRef.current?.focus();
        }
    }, [isInputVisible]);

    const handleChange = (selectedValue) => {
        if (selectedValue === 'other') {
            setIsInputVisible(true);
            setIsFocused(false)
            setValue('other');
            onChange('other')
        } else {
            setValue(selectedValue);
            onChange(selectedValue)
            setIsInputVisible(false);
            setIsFocused(false)
        }
    };

    const handleInputChange = (e) => {
        setOtherValue(e.target.value.replace(/^Other:\s*/, ''));
    };

    const handleInputBlur = () => {
        if (otherValue.trim()) {
            setValue(`Other: ${otherValue.trim()}`);
            onChange(`Other: ${otherValue.trim()}`);
        } else {
            setValue('');
            onChange('')
        }
        if (!isFocused) {
            setIsFocused(true)
        } else {
            setIsInputVisible(false);
            setIsFocused(false)
        }

    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleInputBlur();
            setIsInputVisible(false)
            setIsFocused(false)
        } else if (e.key === 'Escape') {
            setOtherValue('');
            setValue('');
            onChange('')
            setIsInputVisible(false);
            selectRef.current?.focus();
        }
    };

    return (
        <>
            <div style={{ position: 'relative' }} className={className}>
                <Select
                    ref={selectRef}
                    style={{ width: '100%' }}
                    selectValue={selectValue}
                    value={selectValue}
                    onChange={handleChange}
                    placeholder="Select"
                    className={`${style.PHQSelect}`}
                    popupClassName={style.PHQSelectPopup}
                    suffixIcon={<DownArrow />}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                >
                    {options.map(item => (
                        <Option value={item} key={item}>{item}</Option>
                    ))}

                    <Option value="other">
                        {selectValue.startsWith('Other:') ? selectValue : 'Other (Please fill in)'}
                    </Option>
                </Select>
                {isInputVisible && (
                    <Input
                        ref={inputRef}
                        value={`Other: ${otherValue}`}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyDown={handleInputKeyDown}
                        className={style.selectInput}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            padding: '4px 11px',
                            textAlign: 'center',
                        }}
                    />
                )}
            </div>

            {isDropdownOpen && isMobile && (<div className='PHQOverlay'></div>)}
        </>

    );
};

export default CustomSelect;
