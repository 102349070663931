import { message } from "antd";
import moment from "moment";
import { dateFormats } from "@ph/common/global/Constants";
import Cookies from "universal-cookie";
import { REPORT_PLAN_DETAILS } from "../constants/Report/ReportConstants";

export const initiateTokenGeneration = (returnToUrl = window.location.pathname, pageToRedirect = 'dashboard') => {
    const cookies = new Cookies();
    const {
        REACT_APP_MINI_ORANGE_URL,
        REACT_APP_MINI_ORANGE_LOGOUT_PATH,
        REACT_APP_MINI_ORANGE_LOGIN_PATH,
        REACT_APP_MINI_ORANGE_ID,
        REACT_APP_ENV,
        REACT_APP_REDIRECT_URI
    } = process.env;
    const redirectURI = 'auth/dashboard'
    let env = (REACT_APP_ENV === "prod_") ? '' : REACT_APP_ENV;
    cookies.set("returnToUrl", returnToUrl, {path: '/'});
    cookies.set('pageToRedirect', pageToRedirect, {path: '/', domain: '.parallelhealth.io'});
    // https://store.xecurify.com/moas/idp/oidc/logout?
    //   post_logout_redirect_uri=
    //     https%3A%2F%2Fstore.xecurify.com%2Fmoas%2Fbroker%2Flogin%2Fjwt%2F22765%3Fclient_id%3Ddashboard%26
    // redirect_uri%3Dhttps%3A%2F%2Fmytrue.parallelhealth.io%2Fauth%2Fdashboard
    const post_logout_redirect_uri = encodeURIComponent(`${REACT_APP_MINI_ORANGE_URL}/${REACT_APP_MINI_ORANGE_LOGIN_PATH}/${REACT_APP_MINI_ORANGE_ID}?client_id=${env}${pageToRedirect}&redirect_uri=${REACT_APP_REDIRECT_URI}/${redirectURI}`);
    return `${REACT_APP_MINI_ORANGE_URL}/${REACT_APP_MINI_ORANGE_LOGOUT_PATH}?post_logout_redirect_uri=${post_logout_redirect_uri}`;
}

export const buildCompleteShopifyLink = (path) => {
    return `${process.env.REACT_APP_SHOPIFY_URL}/${path}`;
};

export const getShopifyProdUrl = path => {
    return buildCompleteShopifyLink(`products/${path}`);
};

export const pick = (o, props) => {
    return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

export const getFriendlyKitCode = (kitCode) => {
    return (kitCode) ?
        (kitCode.slice(0, 1) + ' ' + kitCode.slice(1)).slice(0, 5) + ' ' + kitCode.slice(4) : '-';
}

export const isValidKitcode = (kitCode) => {
    return kitCode?.length === 7;
}

export const isEmpty = (obj) => {
    return (
        obj &&
        Object.keys(obj).length === 0 &&
        obj.constructor === Object
    );
};

export const deepCloneItem = (itemToClone) => {
    try {
        return JSON.parse(JSON.stringify(itemToClone));
    } catch (ex) {
        return itemToClone;
    }
};

export const convertToURIPath = (path, prefix) => {
    let uriPath = path.toLowerCase().split(" ").join("-");
    if (prefix) {
        uriPath = `${prefix}/${uriPath}`;
    }
    return uriPath;
}

export const getProductDescription = (product, descType) => {
    if(!descType) {
        descType = 'short_text';
    }
    return (product.description && product.description[descType]) || ''
}

export const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([uint8Array], { type: mime });

    return new File([blob], filename, { type: mime });
}

export const handleErrorMsg = (error, alternateMsg = "Something Went Wrong!") => {
    if (error.response && error.response.data && error.response.data.message) {
        message.error(error.response.data.message)
    } else {
        message.error(alternateMsg)
    }
}

export const handleSuccessMsg = (inputMsg) => {
    message.success(inputMsg)
}

export const getFormDataPayload = (requestBody) => {
    const formData = new FormData();

    for (let key in requestBody) {
        if (requestBody.hasOwnProperty(key)) {
            formData.append(key, requestBody[key]);
        }
    }
    return formData
}

export const getFormatedDateTime = (date) => date ? moment(date).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : ''

const getVariantUrl = (productArr, reportPlan, md03Product) => {
    const variant_id_key = REPORT_PLAN_DETAILS[reportPlan]?.product_variant_key
    const selling_plan_key = REPORT_PLAN_DETAILS[reportPlan]?.product_selling_plan_key

    let url = ''

    productArr.forEach(variant => {
        if (variant?.product_id === md03Product?.product_id) {
            // Add MD03 upgrade plan
            url += `items[][id]=${variant?.md_03_upgrade_variant_id}%26items[][quantity]=${1}%26items[][selling_plan]=${variant?.md_03_upgrade_selling_plan_id}%26`
            // Add Remaining quantity of that product
            url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity - 1}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
        } else {
            if (variant[selling_plan_key]) {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26`
            }
        }
    })
    return url
}

export function createCartLink(domain, products, reportPlan, md03Product) {
    const baseUrl = `https://${domain}/cart/clear?return_to=/cart/add?`;
    return `${baseUrl}${getVariantUrl(products, reportPlan, md03Product)}`;
}

export function convertPrice(price) {
    // Convert the price to a number
    const numPrice = Number(price);

    // Check if the price is a whole number
    if (numPrice % 1 === 0) {
        return numPrice.toString(); // Return as a string without decimals
    } else if (numPrice * 10 % 10 === 0) {
        return numPrice.toFixed(1); // Return with one decimal place
    } else {
        return numPrice.toFixed(1); // Return with one decimal place
    }
}

export const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
    });
};

export const scrollToContainer = (containerId) => {
    const container = document.getElementById(containerId);
    if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
        console.warn(`Container with id "${containerId}" not found.`);
    }
};


export function groupArrayElements(arr, n) {
    // Check if arr is an array and n is a positive integer
    if (!Array.isArray(arr) || !Number.isInteger(n) || n <= 0) {
        throw new Error("Invalid input. Please provide an array and a positive integer.");
    }

    // Handle case where n is larger than the array length
    if (n > arr.length) {
        return [arr];
    }

    // Use Array.from() to create the grouped array
    return Array.from({ length: Math.ceil(arr.length / n) }, (_, index) =>
        arr.slice(index * n, (index + 1) * n)
    );
}
