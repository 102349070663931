export const LAB_REPORT_NAV = {
    KIT_ACCESSION: 'Kit Accession',
    BATCH_MANAGEMENT: 'Batch Management',
    HISTORY: 'History',
    REGISTERED_KITS: 'Registered Kits',
    RECEIVED_KITS: 'Received Kits',
    VALIDATED_KITS: 'Validated Kits',
    ON_HOLD_KITS: 'On-hold Kits',
    ACTIVE_BATCHES: 'Active Batches',
    COMPLETED_BATCHES: 'Completed Batches',
    CANCELLED_BATCHES: 'Cancelled Batches',
    COMPLETED_KITS: 'Completed Kits',
    REJECTED_KITS: 'Rejected Kits',
    QUIZ: 'Quiz'
};
