import React from 'react'
import { Tag } from 'antd'
import style from './PhTag.module.scss'

function PhTag({ color = '#2D2926', text = '', icon = '' }) {
    return (
        <Tag color={color} icon={icon} className={style.phTag}>{text}</Tag>
    )
}

export default PhTag