import React from 'react';
import style from "./kitShowcase.module.scss";
import {Button, Col, Row, Skeleton, Typography} from "antd";
import moment from 'moment';
import {dateFormats} from "@ph/common/global/Constants";
import { button_arrow } from '@ph/common/assets/index';

const { Text } = Typography;
function TriColContentHolder({
                                 title,
                                 subtitle,
                                 kitTitle,
                                 kitSubTitle,
                                 productDetailsLoading,
                                 bundleProductDetails,
                                 bundleButtonText,
                                 BundleButtonClickHandler,
                                 imgSrc,
                                 imgClassName = '',
                                 kitButtonClickHandler,
                                 buttonText,
                                 hideButton,
                                 dueDate
                             }) 
    {
    return (
        <Row className={style.kitContent}>
            <Col xs={24} sm={24} md={24} xl={{offset:1, span:8}} className={style.colType1}>
                <Text className={`${style.kitTitle} ${style.mainTitle} ${style.h2Title}`}>{title}</Text>
                <Text className={style.kitSubtitle}>{subtitle}</Text>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} className={style.colType2}>
                {(productDetailsLoading) ?
                    <Skeleton.Image active={true} /> :
                    <div className={`img-container-square ${style.imgContainer} ${imgClassName}`}>
                        <img src={imgSrc} alt='' />
                    </div>
                }
            </Col>
            <Col xs={24} sm={24} md={24} xl={7} className={style.colType3}>
                <Text className={`${style.kitTitle} h4`}>
                    {kitTitle}
                </Text>
                <Text className={style.kitSubtitle}>
                    MD-03 Protocol™ is the first-of-its-kind system combining whole genome sequencing, phage technology, and 1:1 provider care to help you address the root causes of aging, inflammation, acne, eczema, rosacea, and melasma.
                </Text>
                {!hideButton && 
                <div>
                        <Button type="secondary" className={`ant-btn ${style.protocolButton}`}
                            disabled={productDetailsLoading}
                            onClick={kitButtonClickHandler}

                        >
                        {buttonText}
                            <img src={button_arrow} className='ph-btn-arrow' />
                    </Button>
                        {/* {bundleProductDetails && (
                            <Button type="primary" className="ant-btn" style={{ marginTop: '12px', border: "1px solid #FBEEE4" }} onClick={BundleButtonClickHandler}>
                        {bundleButtonText}
                                <img src={button_arrow} className='ph-btn-arrow' />
                    </Button>
                    )} */}
                </div>
                }
                {dueDate && (
                    <Text className={style.kitSubtitle}>
                        {"Due Date: "}
                        {moment(dueDate).format(dateFormats.DDSPCMMMMCOMSPCYYYY)}
                    </Text>
                )}   
            </Col>
        </Row>
    );
}

export default TriColContentHolder;