import React, { useState } from 'react'
import style from "./PHProtocol.module.scss";
import StepDetails from './StepDetails';
import ProtocolCart from './ProtocolCart';
import { PROTOCOL_STEP_TYPES } from '@ph/common/constants/Protocol/constants';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { padProtocolNumber } from './ProtocolHelpers';
import { button_arrow, Back_Arrow_Border } from '@ph/common/assets/index';

import { Button } from 'antd';

function ProtocolSilder() {
    const { currStep, updateCurrStep, protocolData } = useProtocolContext()

    return (
        <div className={style.protocolTabs}>
            {protocolData.steps.map(item =>
                <StepContent key={item.id} currStep={currStep} item={item} updateCurrStep={updateCurrStep} />
            )}
            {protocolData.steps.map(item =>
                <StepLink key={item.id} currStep={currStep} item={item} updateCurrStep={updateCurrStep} />
            )}
        </div>
    )
}

export default ProtocolSilder

const StepContentSelector = ({ stepData }) => {
    switch (stepData.type) {
        case PROTOCOL_STEP_TYPES.PRODUCT:
            return <StepDetails stepData={stepData} />

        case PROTOCOL_STEP_TYPES.CART:
            return <ProtocolCart stepData={stepData} />
        default:
            return <StepDetails stepData={stepData} />
    }
}

const StepContent = ({ item }) => {


    const { currStep, updateCurrStep,
        updateToPreviousStep,
        updateToNextStep,
        protocolData } = useProtocolContext()
    return (
        <>
            <div className={`${style.tabsContent} ${currStep === item.id ? style.active : ''}`}>
                <div className={style.tabWrap}>
                    <StepContentSelector stepData={item} />
                </div>
                <div className={style.buttonBox} style={{ display: currStep === item.id && currStep < 5 ? 'flex' : 'none' }}>
                    <div className={`${style.buttonContainer} ${currStep > 1 ? style.showButton : ''}`}>
                        {currStep > 1 && (
                            <img src={Back_Arrow_Border} alt='back' className={style.backButton} onClick={updateToPreviousStep} />
                        )}
                        <Button type="primary" className={style.nextButton} onClick={updateToNextStep}> Next <img src={button_arrow} className='ph-btn-arrow' /></Button>
                    </div>
                </div>
            </div>
            <a className={`${style.tabsLink} ${style.inlineBlock} ${currStep === item.id ? style.active : ''}`} onClick={() => updateCurrStep(item.id)}>
                <div className={style.protocolNavContainer}>
                    <div className={style.circleContainer} style={{ top: `${(parseInt(item.id) - 1) * 105 + 30}px` }}>
                        <div className={style.halfCircle}>
                            <span className={style.text}>{item.icon ? <img src={item.icon} className={`${style.icon} td-underline`} /> : padProtocolNumber(item.id)}</span>
                        </div>
                        <p className={style.protocolHeading}>{item.label}</p>
                    </div>

                </div>
                <p className={style.tabsHeading}></p>
            </a>
        </>
    )
}

const StepLink = ({ currStep, item, updateCurrStep }) => {
    return (
        <div className={style.verticleNavContainer}>
            <div className={style.circleContainer} style={{ top: `${(parseInt(item.id) - 1) * 105 + 40}px` }}>
                <div className={`${style.halfCircle} ${currStep === item.id ? style.active : ''}`} onClick={() => updateCurrStep(item.id)} >
                    <span className={style.text}>{item.icon ? <img src={item.icon} className={`${style.icon} protocol-icon`} /> : padProtocolNumber(item.id)}</span>
                </div>
            </div>
        </div>
    )
}