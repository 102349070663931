import React, { useState } from 'react'
import style from './quizResult.module.scss'
import QuizProduct from '../QuizProduct/QuizProduct'
import { Row, Col, Button } from 'antd';
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import { button_arrow } from '@ph/common/assets/index';
import QuizCombineProducts from '../QuizProduct/QuizCombineProduct';
import QuizMD03 from '../QuizMD03/QuizMD03';

function QuizProducts({ quizProducts, tags, copyFlag }) {

    const [expandedProduct, setExpandedProduct] = useState('')
    const handleExpandProductClick = (product) => {
        if (product?.variant_id === expandedProduct?.variant_id) {
            setExpandedProduct('')
        } else {
            setExpandedProduct(product)
        }
    }

    const checkExpanded = (product, selectedProduct) => {
        const firstProduct = product
        let productList = [firstProduct]
        const variantIdList = productList.map(item => item.variant_id)
        if (variantIdList.includes(selectedProduct?.variant_id)) {
            return true
        }
        return false
    }
    return (
        <div className={style.recProducts}>
            <Row gutter={[28, 28]} align="stretch" style={{ height: '100%', alignItems: 'stretch' }} className="show-mobile-only">
                {quizProducts && quizProducts.map(product =>
                    <>
                    <Col xs={24} md={12} key={product?.variant_id}>
                            <QuizProduct product={product} otherTags={tags} copyFlag={copyFlag} expandedProduct={expandedProduct} handleExpandProductClick={handleExpandProductClick} />
                    </Col>
                        {expandedProduct && checkExpanded(product, expandedProduct) && (
                            <QuizMD03 productInfo={expandedProduct} setExpandedProduct={handleExpandProductClick} />
                        )}
                    </>
                )}
            </Row>
            <QuizCombineProducts copyFlag={copyFlag} tags={tags} quizProducts={quizProducts} expandedProduct={expandedProduct} handleExpandProductClick={handleExpandProductClick} />
        </div>
    )
}

export default QuizProducts