import React from 'react'
import style from './quizResult.module.scss'
import { QUIZ_PAGE_TEXT } from '@ph/common/constants/QuizConstants';

function ResultsTopContainer({ name, copyFlag, isMd03Member, quizProducts }) {
    return (
        <div className={style.topContainer}>
            <div className={style.thankyouText}>
                THANK YOU FOR COMPLETING THE QUIZ
            </div>
            <div className={style.topBox}>
                {/* <div className={style.title}>
                    CLARITY QUIZ  RECOMMENDATIONS
                </div> */}
                <div className={style.subSection}>
                    <div className={style.name}>Hi {name ? name : 'User'}!</div>
                    <div className={style.subTitle}>
                        Here are your <span className='font-Neue-medium'>{quizProducts ? quizProducts.length : 0}</span> <span className={style.quizText}>Clarity Quiz</span> Product recommendations
                    </div>
                </div>
                {QUIZ_PAGE_TEXT[copyFlag]?.showMd03 && isMd03Member && (
                    <div className={style.lastSection}>
                        <div className={style.image}>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 17.5L8.1225 16.2771C3.23 11.9507 0 9.09727 0 5.59537C0 2.74196 2.299 0.5 5.225 0.5C6.878 0.5 8.4645 1.25041 9.5 2.43624C10.5355 1.25041 12.122 0.5 13.775 0.5C16.701 0.5 19 2.74196 19 5.59537C19 9.09727 15.77 11.9507 10.8775 16.2864L9.5 17.5Z" fill="white" />
                            </svg>
                        </div>
                        <div className={style.text}>
                            <span className="font-Mono-medium">MD-03 Protocol™</span> MEMBERS RECEIVE exclusive discounts on all RECOMMENDED products.
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}

export default ResultsTopContainer