import { Outlet, useLocation } from "react-router-dom";
// import AdminComponent from "@ph/admin/src/component";
import { Row, Col } from "antd";
import logo from '@ph/common/components/Quiz/assets/logo.svg'
import Components from "@ph/common/components";
import { getParentOrderByChildOrder, getParentOrderByPath } from "../utils/quizUtils";
import { useEffect, useState } from "react";
import { header_hex_black } from "../assets/index";



const QuizLayout = ({ children, orderNo }) => {

    return (
        <>
            <Components.QuizHeader title={'Your True Parallel  RECOMMENDATIONS'} />
            <section className="section">
                <div className={"container PHQContainer"}>
                    <div style={{ width: '100%' }}>
                        <Row align="middle" style={{ flexDirection: 'row-reverse' }}>
                            <Col xs={3} md={0} span={12} order={1}>
                                <img src={header_hex_black} className="PHQLogo" />
                            </Col>
                            <img src={header_hex_black} className="PHQLogo show-desktop-tab-hide-mobile" />
                            <Col xs={21} md={24} ><Components.QuizProgressBar currentQue={getParentOrderByChildOrder(orderNo)} /></Col>
                        </Row>
                        {children}
                    </div>
                </div>

            </section>
        </>
    );
};

export default QuizLayout;