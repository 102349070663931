import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import ButtonOptions from './QuiZButtonOption/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Validation schema
const validationSchema = Yup.object().shape({
  skincarePamper: Yup.array().min(1, 'At least one options must be selected').required(' options are required'),
});


function PHQSkincarePamper() {
  const Que = getQuestionByQueId(queConstants.phq_skin_pamper)
  const ans = useSelector(state => state.quizData[queConstants.phq_skin_pamper])
  const dispatch = useDispatch();

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      skincarePamper: ans || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>How often do you visit a medspa for botox, fillers, lasers, or other beauty treatments?  </div>
            <div className='PHQSpacer'></div>
            <div className={style.smallButtonOption}>
              <Controller
                name="skincarePamper"
                control={control}
                render={({ field }) => (
                  <ButtonOptions
                    question={`What are your current skin concerns?`}
                    options={[
                      'I’m a frequent client - 4 or more times a year',
                      'I go 1-3 times a year for my beauty boost',
                      'Never. What’s a medspa? ',
                    ]}
                    values={ans}
                    onChange={(value) => {
                      field.onChange(value);
                      dispatch(updateQuizData({ queID: queConstants.phq_skin_pamper, ans: value }))

                    }}
                    desktopCol={1}
                    background='linear-gradient(133.34deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                    multiSelection={true}
                    backgroundMobile='linear-gradient(160deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                  />
                )}
              />

            </div>
            <p className="PHQErrorMessage">
              {errors.skincarePamper && (
                <span >{errors.skincarePamper.message}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQSkincarePamper