import { Input, Button, Row, Col } from 'antd'
import style from './quizButtonOption.module.scss'
import { useEffect, useRef, useState } from 'react';
import { DO_NOT_KNOW } from '@ph/common/constants/QuizConstants';

const OTHER_BUTTON = 'Other'
const ButtonOptions = ({
  question, options, values, onChange, desktopCol = 3, otherAllowed = false, multiSelection = false, background = 'linear-gradient(101.47deg,#eaffa6 4.16%,#f1ddff 42.11%,#f1ddff 61.04%,#25a18e 96.65%)', backgroundMobile = 'linear-gradient(160deg, #EAFFA6 4.16%, #F1DDFF 42.11%, #F1DDFF 61.04%, #25A18E 96.65%)' }) => {


  const allOptions = [...options, DO_NOT_KNOW]
  const finalOptions = otherAllowed ? [...options, OTHER_BUTTON, DO_NOT_KNOW] : options;

  const styles = {
    '--desktop-bg': background,
    '--mobile-bg': backgroundMobile
  }
  const handleOnChange = (newValue) => {
    let valuesCopy = values
    if (!Array.isArray(valuesCopy))
      valuesCopy = []

    if (newValue == null) return;

    if (newValue === DO_NOT_KNOW) {
      const alreadyExits = values.filter(item => item === DO_NOT_KNOW)
      if (alreadyExits && alreadyExits.length > 0) {
        onChange([])
      } else {
        onChange([DO_NOT_KNOW])
      }
      return
    }

    if (newValue === '' || newValue === OTHER_BUTTON) {
      const filterArray = values?.filter(item => allOptions.includes(item));
      onChange(filterArray)
      return
    }
    let updatedValues = []
    if (!multiSelection) {
      if (isAlreadyPresented(newValue)) {
        updatedValues = valuesCopy.includes(newValue)
          ? valuesCopy.filter(item => item !== newValue)
          : [...valuesCopy, newValue];
      } else {
        const filerArray = values?.filter(item => allOptions.includes(item));
        if (filerArray) {
          updatedValues = [...filerArray, newValue]

        } else {
          updatedValues = [newValue]
        }
      }
    } else {
      updatedValues = [newValue]
    }

    onChange(updatedValues.filter(item => item !== DO_NOT_KNOW));
  };

  const getOtherValue = () => {
    const difference = values?.filter(item => !allOptions.includes(item));
    if (difference && difference.length > 0) {
      return difference[0]
    }
    return ''
  }

  const isAlreadyPresented = (newValue) => {
    return allOptions.includes(newValue)
  }
  const calcDesktopCol = 24 / desktopCol

  return <Row className={style.buttonOptionsContainer} style={styles}>
    {
      finalOptions.map((opt, i) => {
        return opt === OTHER_BUTTON ? <OtherButton value={getOtherValue()} calcDesktopCol={calcDesktopCol} isSelected={getOtherValue()} handleOnChange={handleOnChange} />
          :
          <Col xs={24} md={calcDesktopCol}>
            <div
              className={`${style.gridCell} ${values?.includes(opt) ? style.selected : ''}`}
              onClick={() => handleOnChange(opt)}
            >
              {opt}
            </div>
          </Col>
      })
    }</Row>
}


export default ButtonOptions

const OtherButton = ({ value, calcDesktopCol, isSelected, handleOnChange }) => {
  const [inputValue, setInputValue] = useState(value ? value : OTHER_BUTTON)
  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const handleFocus = () => {
    setIsFocused(true);
    if (inputValue === OTHER_BUTTON) {
      setInputValue('')
    }
  };

  const handleOtherClick = (e) => {
    inputRef?.current?.focus();
  }


  const handleInputBlur = () => {
    if (!inputValue && !inputValue?.trim() || inputValue?.trim() === OTHER_BUTTON) {
      setInputValue(OTHER_BUTTON)
    }
    handleOnChange(inputValue)
    setIsFocused(false);
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      handleInputBlur()
    }
  }

  return (
    <Col xs={24} md={calcDesktopCol} onClick={handleOtherClick}>
      <div
        className={`${style.gridCell} ${style.other} ${(isSelected || isFocused) ? style.selected : ''}`}
      >
        <Input
          ref={inputRef}
          type='text'
          value={inputValue}
          className={style.otherInput}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleInputBlur}
          onFocus={handleFocus}
          onKeyDown={handleEnterKey}
          allowClear={isFocused ? true : false}
        />
      </div>

    </Col>
  )
}
