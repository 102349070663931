import React, { useEffect, useState } from 'react'
import style from './ReportSections.module.scss'
import { Row, Col } from 'antd'
import TableContainer from '../ReportContainer/TableContainer'
import DescriptionContainer from '../ReportContainer/DescriptionContainer'
import RecommendationInfo from '../ReportContainer/RecommendationInfo'
import { capitalizeFirstLetter } from '../reportUtils'

function RecommendationSection({ color, recommendationData, columnNames }) {
    const [selectedItem, setSelectedItem] = useState({})


    useEffect(() => {
        if (recommendationData && recommendationData.length > 0) {
            setSelectedItem(recommendationData[0])
        }
    }, [])

    return (
        <Row>
            <Col xs={0} md={14} lg={16}>
                <TableContainer columnNames={columnNames} color={color} tableData={recommendationData} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            </Col>
            <Col xs={0} md={10} lg={8}>
                <DescriptionContainer title={columnNames[2]} subTitle={selectedItem?.name} description={capitalizeFirstLetter(selectedItem?.benefits)} color={color} titleBorderColor='#FFFFFF' />
            </Col>
            <Col xs={24} md={0}>
                <RecommendationInfo RecommendationInfoList={recommendationData} expandIconPosition={'end'} color={color} />
            </Col>
        </Row>
    )
}

export default RecommendationSection