import connectionInstance from "@ph/admin/src/api_services/connection";

const { REACT_APP_API_URL } = process.env;

const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export function getRecordsCount() {
    //get_table_record_count
    return connectionInstance.get(
        `${REACT_APP_API_URL}/get_table_record_count`
    );
}

function getCommonParamsForListAPIs(params, { startAndEndDate, offset, limit, sortOrder, sortBy }) {
    if (startAndEndDate && startAndEndDate.hasOwnProperty('startDate') &&
        startAndEndDate.hasOwnProperty('endDate')) {
        params.start_date = startAndEndDate.startDate;
        params.end_date = startAndEndDate.endDate;
    }
    if (typeof offset === 'number') {
        params.offset = offset;
    }
    if (typeof limit === 'number') {
        params.limit = limit;
    }
    if (sortOrder) {
        params.sort_order = sortOrder;

        if (sortBy) {
            params.sort_by = sortBy;
        }
    }
    return params;
}

export function getKitsList(kitListType, { kitCodeFilter, ...commonParams }) {
    if (!kitListType) {
        kitListType = 'registered';
    }
    let getListForKitCode = 'ALL', params = {};
    if (kitCodeFilter && kitCodeFilter.length === 7) {
        getListForKitCode = kitCodeFilter;
    }
    params = getCommonParamsForListAPIs(params, commonParams);
    return connectionInstance.get(
        `${REACT_APP_API_URL}/kit/${getListForKitCode}/${kitListType}`,
        { params }
    ).then(result => {
        result.data.kits_info_list = result.data.kits_info_list.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function getBatchList(batchType, { kitCodeFilter, ...commonParams }) {
    if (!batchType) {
        batchType = 'active';
    }
    let getListForKitCode = 'ALL', params = {};
    if (kitCodeFilter && kitCodeFilter.length > 7) {
        getListForKitCode = kitCodeFilter;
    }
    params = getCommonParamsForListAPIs(params, commonParams);
    return connectionInstance.get(
        `${REACT_APP_API_URL}/batch/${getListForKitCode}/${batchType}`,
        { params }
    ).then(result => {
        result.data.batch_list = result.data.batch_list.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function receiveKit(kitCode, data) {
    return connectionInstance.post(
        `${REACT_APP_API_URL}/kit/${kitCode}/receive`, encodeGetParams(data)
    );
}

export function moveReceivedKitToRegistered(kitCode) {
    return connectionInstance.delete(
        `${REACT_APP_API_URL}/kit/${kitCode}/receive`
    );
}

export function validateKits(kitCode, formData) {
    return connectionInstance.patch(
        `${REACT_APP_API_URL}/kit/${kitCode}/validate`, formData, {
        headers: {
            ...connectionInstance.defaults.headers,
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function getPrescriptionCount(prescription_type) {
    let params = {};
    if (prescription_type) {
        params = { prescription_type };
    }
    return connectionInstance.get(
        `${REACT_APP_API_URL}/prescription/count`, { params }
    );
}

export function getPrescriptionList(prescriptionType,
    { status, subjectNameFilter, ...commonParams }) {
    if (!prescriptionType) {
        prescriptionType = 'Acne';
    }
    let params = {};
    params.status = (!status) ? 'pending_review' : status;
    if (subjectNameFilter) {
        params.subject_name = subjectNameFilter;
    }
    params = getCommonParamsForListAPIs(params, commonParams);
    return connectionInstance.get(
        `${REACT_APP_API_URL}/prescription/all/${prescriptionType}`,
        { params }
    ).then(result => {
        result.data.prescription_order_list = result.data.prescription_order_list.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function getPrescriptionFormSubmissions(submissionId, submittedBy) {
    const activePrescriptionType = localStorage.getItem('currentNavLabel');
    return connectionInstance.get(
        `${REACT_APP_API_URL}/prescription/${submissionId}/${submittedBy}_jotform_data?prescription_type=${activePrescriptionType}`
    ).then(result => {
        result.data = result.data.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function getPhysicianJotformLink(prescription_order_id) {
    return connectionInstance.post(
        `${REACT_APP_API_URL}/prescription/${prescription_order_id}/physicians_prescription`
    );
}

export function movePrescriptionToPendingState(prescription_order_id) {
    return connectionInstance.delete(`${REACT_APP_API_URL}/prescription/${prescription_order_id}/physicians_prescription`);
}
export const globalSearchKit = (kitCode) => connectionInstance.get(`${REACT_APP_API_URL}/kit/${kitCode}/search`)
// Batch APIs
export const createNewBatch = () => connectionInstance.post(`${REACT_APP_API_URL}/batch`, encodeGetParams({}))
export const getBatchDetails = (batchCode) => connectionInstance.get(`${REACT_APP_API_URL}/batch/${batchCode}`)
export const cancelBatch = (batchCode) => connectionInstance.delete(`${REACT_APP_API_URL}/batch/${batchCode}`)
export const getBatchCSVData = (batchCode) => connectionInstance.get(`${REACT_APP_API_URL}/batch/${batchCode}/download`)
export const addKitToBatch = (batchCode, data) => connectionInstance.post(`${REACT_APP_API_URL}/batch/${batchCode}/kit`, encodeGetParams(data))
export const deleteKitFromBatch = (batchCode, kitcode, kitType) => connectionInstance.delete(`${REACT_APP_API_URL}/batch/${batchCode}/kit?kitcode=${kitcode}&kit_type_name=${kitType}`) // data = {kitcode : "ABW" ,kit_type_name : "Skin MircorBiom"}
export const skipBatchCell = (batchCode, data) => connectionInstance.post(`${REACT_APP_API_URL}/batch/${batchCode}/skip`, encodeGetParams(data)) // data = {cell_position : E2}
export const addControlToBatch = (batchCode, data) => connectionInstance.post(`${REACT_APP_API_URL}/batch/${batchCode}/control`, encodeGetParams(data)) // data = {control_type : "CTR|CTW",cell_position: "E2"}
export const deleteControlFromBatch = (batchCode, cellPosition, controlType) => connectionInstance.delete(`${REACT_APP_API_URL}/batch/${batchCode}/control?cell_position=${cellPosition}&control_type=${controlType}`) // data = {control_type : "CTW1|CTW2|CTW3|CTR1|CTR2|CTR3",cell_position: "E2"}
export const addBatchHistory = (batchCode, data) => connectionInstance.post(`${REACT_APP_API_URL}/batch/${batchCode}/history`, encodeGetParams(data))
export const deleteBatchHistory = (batchCode, batchStatus) => connectionInstance.delete(`${REACT_APP_API_URL}/batch/${batchCode}/history?batch_history_status=${batchStatus}`)
export const updateBatchHistory = (batchCode, data) => connectionInstance.patch(`${REACT_APP_API_URL}/batch/${batchCode}/history`, encodeGetParams(data)) // data = {batch_history_status : "CTR|CTW",cell_position: "E2"}
export const getKitTypeSwabInfo = () => connectionInstance.get(`${REACT_APP_API_URL}/kit/kit_type_swab_info`)

export const updateReportStatus = (data) => connectionInstance.post(`${REACT_APP_API_URL}/kit/approve_report`, encodeGetParams(data)) // data = {batch_history_status : "CTR|CTW",cell_position: "E2"}

export const getAllQuizData = () => connectionInstance.get(`${REACT_APP_API_URL}/quiz/all`) 