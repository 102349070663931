import React, { useState, useEffect, useRef } from 'react';
import style from './phTagContainer.module.scss'
import { Popover, Grid } from 'antd';

const { useBreakpoint } = Grid

const ReadMore = <svg width="30" height="30" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18.5" cy="18.5" r="17.5" fill="#2D2926" stroke="#2D2926" strokeWidth="2" />
    <circle cx="9.53451" cy="18.5345" r="2.53451" fill="white" />
    <circle cx="18.5345" cy="18.5345" r="2.53451" fill="white" />
    <circle cx="27.5345" cy="18.5345" r="2.53451" fill="white" />
</svg>

const ReadLess = <svg width="30" height="30" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18.5" cy="18.5" r="17.5" fill="#2D2926" stroke="#2D2926" strokeWidth="2" />
    <path d="M11.7852 11.7871L18.3915 18.3935L24.9979 24.9998" stroke="white" strokeWidth="1.5" />
    <path d="M11.2871 25.501L18.3939 18.3942L25.5006 11.2874" stroke="white" strokeWidth="1.5" />
</svg>

const PHTagContainer = ({ tags }) => {
    const [visibleTags, setVisibleTags] = useState([]);
    const [displayTags, setDisplayTags] = useState([])
    const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
    const [hiddenTags, setHiddenTags] = useState([])
    const [openPopOver, setPopOver] = useState(false)
    const containerRef = useRef(null);
    const screens = useBreakpoint();
    const isMobile = screens.xs

    const handleMoreClick = () => {
        setPopOver(!openPopOver)
        if (!openPopOver) {
            setDisplayTags([...visibleTags, ...hiddenTags])
        } else {
            setDisplayTags([...visibleTags])
        }

    }

    useEffect(() => {
        const updateTags = () => {
            if (!containerRef.current) return;
            const containerLines = isMobile ? 2 : 1;
            const containerWidth = containerRef.current.offsetWidth * containerLines;
            let totalWidth = 0;
            let consumedWidth = 0
            const visible = [];
            const hiddenTag = []
            let hidden = 0;

            // Calculate widths without actually adding elements to the DOM
            const getElementWidth = (content, className) => {
                const tempSpan = document.createElement('span');
                tempSpan.className = className;
                tempSpan.style.visibility = 'hidden';
                tempSpan.style.position = 'absolute';
                tempSpan.textContent = content;
                document.body.appendChild(tempSpan);
                const width = tempSpan.offsetWidth;
                document.body.removeChild(tempSpan);
                return width;
            };

            for (const tag of tags) {
                let currWidth = 0
                const tempSpan = document.createElement('span');
                tempSpan.className = style.tag;
                tempSpan.textContent = tag;
                containerRef.current.appendChild(tempSpan);
                totalWidth += tempSpan.offsetWidth + 8; // 8px for margin-right
                currWidth = tempSpan.offsetWidth + 8
                containerRef.current.removeChild(tempSpan);

                if (totalWidth > containerWidth) {
                    hidden++;
                    hiddenTag.push(tag)
                } else {
                    visible.push(tag);
                    consumedWidth += currWidth
                }
            }

            if (hidden > 0) {
                const moreTagsWidth = getElementWidth(ReadMore, style.moreTags);
                if (consumedWidth + moreTagsWidth > containerWidth) {
                    hiddenTag.push(visible.pop())
                    hidden++;
                }
            }


            setVisibleTags(visible);
            if (openPopOver) {
                setDisplayTags([...visible, ...hiddenTag])
            } else {
                setDisplayTags([...visible])
            }
            setHiddenTagsCount(hidden);
            setHiddenTags(hiddenTag)
        };

        updateTags();
        // window.addEventListener('resize', updateTags);
        // return () => window.removeEventListener('resize', updateTags);
    }, [tags]);

    return (
        <div className={style.container}>
            <div className={style.tagContainer} ref={containerRef} onClick={handleMoreClick}>
                {displayTags.map((tag, index) => (
                    <span key={index} className={style.tag}>
                        {tag}
                    </span>
                ))}
                {hiddenTagsCount > 0 && !openPopOver && (
                    <span className={style.moreTags} onClick={handleMoreClick}>{ReadMore}</span>
                )}
                {hiddenTagsCount > 0 && openPopOver && (
                    <span className={style.moreTags} onClick={handleMoreClick}>{ReadLess}</span>
                )}
            </div>
        </div>
    );
};

export default PHTagContainer;
