import axios from "axios";
import { AuthToken } from "./localstorage";
import { message } from 'antd';

const connectionInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  // withCredentials: true,
  crossdomain: true,
});

export const dummyInstance = axios.create({
  baseURL: "http://editor.swagger.io/",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

const tokenUrl = `${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`;

const getNewToken = async () => {
  try {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append(
      "refresh_token",
      AuthToken.getToken('ph_admin_refresh_token')
    );
    params.append("client_id", process.env.REACT_APP_AWS_CLIENT_ID);
    params.append('client_secret', process.env.REACT_APP_AWS_CLIENT_SECRET);
    const res = await axios.post(tokenUrl, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    if (res && res.data) {
      AuthToken.setToken('ph_admin_access_token', res.data.access_token);
      AuthToken.setToken('ph_admin_token', res.data.id_token);
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *  set request interceptors before sending request
 */
connectionInstance.interceptors.request.use(
  (config) => {
    const idToken = AuthToken.getToken('ph_admin_token');
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

connectionInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // if (error.response && error.response.status === 440) {
    //   AuthToken.removeStorage();
    //   // AuthUser.removeUser();
    //   window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
    //   return Promise.reject(error);
    // }

    if (error && error.response && error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error && error.response && error.response.status === 401 && error.config.url === tokenUrl) {
      message.error("Session expired. Please login again.");
      AuthToken.removeStorage();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(error);
    }

    try {
      const tokenResponse = await getNewToken();
      if (tokenResponse && tokenResponse.data && tokenResponse.data.id_token) {
        const { config } = error;
        config.headers.Authorization = `Bearer ${tokenResponse.data.id_token}`;
        return await axios.request(config);
      }
      AuthToken.removeStorage();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(error);
    } catch (err) {
      AuthToken.removeStorage();
      window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
      return Promise.reject(err);
    }
  }
);

export default connectionInstance;
