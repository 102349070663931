import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import ButtonOptions from './QuiZButtonOption/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { scrollToContainer } from '@ph/common/global/Helpers'
import { useEffect } from 'react'

// Validation schema
const validationSchema = Yup.object().shape({
  skincareCuriosity: Yup.array().min(1, 'At least one options must be selected'), // Ensure at least one issue is selected
});



function PHQSkincareCuriosity() {
  const Que = getQuestionByQueId(queConstants.phq_skincare_curiosity)
  const ans = useSelector(state => state.quizData[queConstants.phq_skincare_curiosity])
  const dispatch = useDispatch();

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      skincareCuriosity: ans || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.skincareCuriosity) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.skincareCuriosity])

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>When it comes to supercharging your skin health, what are you curious about?</div>
            <div className='PHQMessage'>■ choose all that apply</div>
            <div className='PHQSpacer'></div>
            <div className={`${style.midButtonOption}`}>
              <Controller
                name="skincareCuriosity"
                control={control}
                render={({ field }) => (
                  <ButtonOptions
                    question={`What are your current skin concerns?`}
                    options={[
                      'Foundational skincare—building a solid base for glowing skin',
                      'Skin testing—uncovering the secrets of my skin microbiome',
                      'Precision skincare products—customized magic for my unique needs',
                      'Anti-aging elixirs—turning back the clock with a touch of magic'
                    ]}
                    values={field.value}
                    onChange={(value) => {
                      field.onChange(value); // Update form state
                      dispatch(updateQuizData({ queID: queConstants.phq_skincare_curiosity, ans: value }))
                    }}
                    desktopCol={1}
                    background='linear-gradient(133.34deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                    backgroundMobile='linear-gradient(160deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                  />
                )}
              />
            </div>
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.skincareCuriosity && (
                <span >{errors.skincareCuriosity.message}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} final={true} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQSkincareCuriosity