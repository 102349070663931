import React, { useEffect, useState } from 'react'
import style from './quizMd03.module.scss'
import { Row, Col, Checkbox } from 'antd';
import PhTag from '../../Global/PhTag/PhTag';
import { quiz_md03, quiz_md03_serum, quiz_cream, quiz_report } from '@ph/common/assets/index';
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import { convertPrice } from '@ph/common/global/Helpers';
import PHTagContainer from '../../Global/PhTagContainer/PhTagContainer';
import { DO_NOT_KNOW } from '@ph/common/constants/QuizConstants';

const MD03_LABEL = {
    "Skin Discovery Test": "Facial skin",
    "Body Blemish Test": "Body skin",
    "Odor Discovery Test": "Odor",
    "Body Blemish Test + Serum": "Body skin",
    "Odor Discovery Test + Serum": "Odor"
}

function QuizMD03({ tags, productInfo, setExpandedProduct, className }) {
    const [checked, setChecked] = useState(false)
    const { products, addProduct, removeProduct, isMd03Member } = useQuizCart()
    const productTags = tags?.flat()?.filter(item => item !== DO_NOT_KNOW)

    const handleToggleCheck = () => {
        if (checked) {
            removeProduct(productInfo?.variant_id)
        } else {
            addProduct({ ...productInfo, quantity: 1, product_category: 'MD03' })
        }
    }

    useEffect(() => {
        const isPresent = products.filter(item => item.variant_id === productInfo.variant_id)
        if (isPresent && isPresent.length > 0) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [products])

    useEffect(() => {
        if (productInfo) {
            addProduct({ ...productInfo, quantity: 1, product_category: 'MD03' })
        }
    }, [])

    return (
        <div className={`${style.md03Container} ${productInfo ? style.open : ''} ${className}`}>
            <div className={style.titleContainer}>
                <Row gutter={[40, 20]}>
                    <Col xs={24}>
                        <div className={style.title}>
                            What’s Included?
                        </div>
                    </Col>

                    <Col xs={24} md={18}>
                        <div className={style.description}>
                            Since you're looking for an ongoing solution for {MD03_LABEL[productInfo?.product_name]?.toLowerCase()} issues, we recommend the MD-03 Protocol™ with the {productInfo?.product_name}.  First we TEST your skin and you recieve your skin health report. Next, we provide you microbiome essentials to begin to RESET your skin. After that, we will formulate a custom serum based on the findings from your health report, for you start REBALANCING your skin's micriobiome.
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div className={style.subtitle}>
                            HSA / FSA Eligible<br />
                            Adjust, pause, or cancel anytime after<br />
                            3-month Introductory Experience.<br />
                        </div>
                    </Col>

                </Row>
            </div>
            <div className={style.productContainer}>
                <Row gutter={30}>
                    <Col xs={24} md={8}>
                        <div className={style.imageContainer}>
                            <img src={quiz_md03} alt="kit" />
                        </div>
                    </Col>
                    <Col xs={24} md={16}>
                        <div className={style.productInfo}>
                            <Row gutter={20}>
                                <Col xs={24} md={10}>
                                    <div className={style.leftContainer}>
                                        <div className={style.label}>
                                            MONTH 01 + EVERY 6 MONTHS
                                        </div>
                                        <div className={style.title}>
                                            {productInfo?.product_name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={14} className="show-desktop-tab-hide-mobile">
                                    <div className={style.rightContainer}>
                                        <div className={style.infoContainer}>
                                            <div className={style.question}>
                                                Why it's important to TEST.
                                            </div>
                                            <div className={style.info}>
                                                In month 1, you receive our simple to use {productInfo?.product_name} It only takes a few minutes to conduct the test at home and drop it off at your local USPS. You receive a free skin test every 6 months.
                                            </div>
                                        </div>
                                        <div className={style.guide}>
                                            ■ 1:1 CLINICAL GUIDANCE, 24/7 TEXT ACCESS
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={24} md={8}>

                        <div className={style.multiImageContainer}>
                            <img src={quiz_cream} />
                            <img src={quiz_report} />
                        </div>

                    </Col>
                    <Col xs={24} md={16}>
                        <div className={style.productInfo}>
                            <Row gutter={20}>
                                <Col xs={24} md={10}>
                                    <div className={style.leftContainer}>
                                        <div className={style.label}>
                                            MONTH 02
                                        </div>
                                        <div className={style.title}>
                                            Daily Reset Duo <br />
                                            <span className={style.nextTitle}>
                                                Holy Calming Cleanser + Skin Barrier Silk Cream
                                            </span>
                                        </div>
                                        <div className={`${style.title} ${style.secondTitle}`}>
                                            Health Report  <br />
                                            <span className={style.nextTitle}>
                                                with medical consult
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={14} className="show-desktop-tab-hide-mobile">
                                    <div className={style.rightContainer}>
                                        <div className={style.infoContainer}>
                                            <div className={style.question}>
                                                Why it's important to RESET.
                                            </div>
                                            <div className={style.info}>
                                                In month 2, you receive our Daily Reset Duo, which includes our cleanser and moisturizer. Use these microbiome friendly products to reset your skin microbiome prior to using your custom phage serum.
                                            </div>
                                            <div className={style.info}>
                                                Throughout this process, you have access to our clinical team. You can text us to our secure number 24/7 and you can book a call/zoom at any time that you're an MD-03 member.
                                            </div>
                                        </div>
                                        <div className={style.guide}>
                                            ■ 1:1 CLINICAL GUIDANCE, 24/7 TEXT ACCESS
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={24} md={8}>
                        <div className={style.imageContainer}>
                            <img src={quiz_md03_serum} alt="serum" />
                        </div>
                    </Col>
                    <Col xs={24} md={16}>
                        <div className={style.productInfo}>
                            <Row gutter={20}>
                                <Col xs={24} md={10}>
                                    <div className={style.leftContainer}>
                                        <div className={style.label}>
                                            MONTH 03 + BEYOND
                                        </div>
                                        <div className={style.title}>
                                            Custom Active Phage Serum
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={14} className="show-desktop-tab-hide-mobile">
                                    <div className={style.rightContainer}>
                                        <div className={style.infoContainer}>
                                            <div className={style.question}>
                                                Why it's important to REBALANCE.
                                            </div>
                                            <div className={style.info}>
                                                In month 3, and every month thereafter, use your Custom Active Phage Serum unique to your skin needs.
                                            </div>
                                        </div>
                                        <div className={style.guide}>
                                            ■ 1:1 CLINICAL GUIDANCE, 24/7 TEXT ACCESS
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={0} md={8}>
                    </Col>
                    <Col xs={24} md={16}>
                        <div className={style.buttonContainer}>
                            <div className={style.first} onClick={() => window.open('https://www.parallelhealth.io/pages/md03-phage-protocol', '_blank')}>
                                I want to learn more
                            </div>
                            <div className={`${style.addButton} ${checked ? style.checked : ''}`} onClick={handleToggleCheck}>
                                {checked ? 'Added' : 'Add'} to cart
                                <Checkbox checked={checked} className={style.checkbox} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={style.includedContainer} onClick={() => setExpandedProduct(productInfo)}>
                <div className={style.icon}>
                    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.502036 32.7086C0.509146 33.2608 0.962589 33.7027 1.51483 33.6956L10.5141 33.5797C11.0663 33.5726 11.5082 33.1192 11.5011 32.5669C11.494 32.0147 11.0406 31.5728 10.4883 31.5799L2.489 31.6829L2.386 23.6836C2.37889 23.1313 1.92545 22.6894 1.37321 22.6965C0.82097 22.7036 0.379056 23.1571 0.386166 23.7093L0.502036 32.7086ZM32.5019 1.90021C32.4948 1.34797 32.0413 0.906058 31.4891 0.913169L22.4898 1.02904C21.9376 1.03615 21.4957 1.48959 21.5028 2.04183C21.5099 2.59407 21.9633 3.03598 22.5156 3.02887L30.5149 2.92588L30.6179 10.9252C30.625 11.4775 31.0785 11.9194 31.6307 11.9123C32.1829 11.9051 32.6249 11.4517 32.6177 10.8995L32.5019 1.90021ZM2.2181 33.3936L32.2181 2.61103L30.7858 1.21514L0.785801 31.9977L2.2181 33.3936Z" fill="white" />
                    </svg>
                </div>
                <div className={`${style.text}`} >
                    READ LESS
                </div>
            </div>
        </div>
    )
}

export default QuizMD03