import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import QuizFaceArea from '../QuizFaceArea/QuizFaceArea'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'

// Validation schema
const validationSchema = Yup.object().shape({
  faceArea: Yup.array().min(1, 'At least one option must be selected'), // Ensure at least one face area is selected
});

function PHQFaceArea() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_face_area);
  const nextQue = getQuestionByQueId(queConstants.phq_face_issues);
  const faceAreaAns = useSelector(state => state.quizData[queConstants.phq_face_area]);

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      faceArea: faceAreaAns || [],
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.faceArea) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.faceArea])

  const errorMessages = Object.values(errors).map(error => error.message).join('\n');

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
        <div className={style.questionWrapper}>
          <div className='PHQSpacer'></div>

          <Controller
            name="faceArea"
            control={control}
            render={({ field }) => (
              <QuizFaceArea
                values={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  dispatch(updateQuizData({ queID: queConstants.phq_face_area, ans: value }));
                }}
              />
            )}
          />
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.faceArea && (
                <span >{errors.faceArea.message}</span>
              )}
            </p>
        </div>
        </div>
      </div>
      <QueButtons prevQue={Que} currentQue={faceAreaAns?.includes(NO_ISSUES_TEXT) ? nextQue : Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQFaceArea;
