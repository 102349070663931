import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import userDataSlice from "./userData.reducer";
import quizDataSlice from "./quizData.reducer";
import reportDataSlice from "./reportData.reducer";

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const reducers = combineReducers({
  userData: userDataSlice.reducer,
  reportData: reportDataSlice.reducer,
  quizData: quizDataSlice.reducer
});

const _persistedReducer = persistReducer(persistConfig, reducers);

let store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER
      ],
    },
  }),
});
let persistor = persistStore(store);
export { store, persistor } 