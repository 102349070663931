import { Input, Button, Select, Grid } from 'antd'
import style from './quizSelect.module.scss'
import { useState } from 'react'

const { useBreakpoint } = Grid;
const DownArrow = () => {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1.56201L10.062 10.124L18.624 1.56201" stroke="#ABBDEA" stroke-width="2" stroke-linecap="round" />
    </svg>
  )
}

const QuizSelect = ({ placeholder, options, className = '', popupClassName = '', ...props }) => {

  const screens = useBreakpoint();
  const isMobile = screens.xs
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownVisibleChange = (open) => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return <><Select
    className={`${style.PHQSelect} ${className}`}
    placeholder={placeholder}
    popupClassName={`${style.PHQSelectPopup} ${popupClassName}`}
    suffixIcon={<DownArrow />}
    onDropdownVisibleChange={handleDropdownVisibleChange}
    {...props}
  >
    {
      options.map((opt, i) => {
        return <option style={{ textAlign: 'center' }} value={opt} className={style.PHQSelectOption}
        >{opt}</option>
      })
    }</Select>
    {isDropdownOpen && isMobile && (<div className='PHQOverlay'></div>)}
  </>
}


export default QuizSelect
