import React, { createContext, useContext, useReducer } from 'react';
import { REPORT_PLAN_MODAL_TYPES, REPORT_PLANS } from '../constants/Report/ReportConstants';
import { QUIZ_PLAN_MODAL_TYPES } from '../constants/QuizConstants';

// Define the context
const QuizCartContext = createContext();

// Initial state for the cart
const initialState = {
    products: [],
    md03Products: [],
    isMd03Member: false,
    quizModal: false,
    quizModalType: QUIZ_PLAN_MODAL_TYPES.DOWNGRADE,
    total: 0,
};

// Utility function to calculate the total price
const calculateTotal = (products) =>
    products.reduce((acc, curr) => acc + curr.price * 1, 0);

// Actions
const actionTypes = {
    ADD_PRODUCT: 'ADD_PRODUCT',
    REMOVE_PRODUCT: 'REMOVE_PRODUCT',
    UPDATE_QUANTITY: 'UPDATE_QUANTITY',
    UPDATE_INCLUDED_PRODUCTS: 'UPDATE_INCLUDED_PRODUCTS',
    UPDATE_MD03_UPGRADE_PRODUCTS: 'UPDATE_MD03_UPGRADE_PRODUCTS',
    UPDATE_MD03_PRODUCT: 'UPDATE_MD03_PRODUCT',
    UPDATE_MD03_MEMBERSHIP: 'UPDATE_MD03_MEMBERSHIP',
    UPDATE_REPORT_PLAN: 'UPDATE_REPORT_PLAN',
    UPDATE_PLAN_MODAL: 'UPDATE_PLAN_MODAL',
    UPDATE_PLAN_MODAL_TYPE: 'UPDATE_PLAN_MODAL_TYPE',
};

// Reducer to handle actions
function cartReducer(state, action) {
    switch (action.type) {
        case actionTypes.ADD_PRODUCT:
            // Check if product already exists
            const existingIndex = state.products.findIndex(
                (product) => product.variant_id === action.payload.variant_id
            );

            // If it exists, update the quantity
            if (existingIndex > -1) {
                let updatedProducts = [...state.products];
                // set quantity always to 1
                updatedProducts[existingIndex].quantity = 1;

                return {
                    ...state,
                    products: updatedProducts,
                    total: calculateTotal(updatedProducts),
                };
            }

            // If it doesn't exist, add the product
            return {
                ...state,
                products: [...state.products, action.payload],
                total: calculateTotal([...state.products, action.payload]),
            };
        case actionTypes.REMOVE_PRODUCT:
            const filteredProducts = state.products.filter(
                (product) => product.variant_id !== action.payload.id
            );

            return {
                ...state,
                products: filteredProducts,
                total: calculateTotal(filteredProducts),
            };
        case actionTypes.UPDATE_QUANTITY:
            const updatedProducts = state.products.map((product) =>
                product.product_id === action.payload.id
                    ? { ...product, quantity: action.payload.quantity }
                    : product
            );

            return {
                ...state,
                products: updatedProducts,
                total: calculateTotal(updatedProducts),
            };
        case actionTypes.UPDATE_INCLUDED_PRODUCTS:
            const newIncludedProducts = action.payload
                .filter(product => product.is_included)
                .map(product => ({
                    ...product,
                    quantity: 1,
                    md_03_price: 0
                }));

            return {
                ...state,
                includedProducts: newIncludedProducts
            };

        case actionTypes.UPDATE_MD03_UPGRADE_PRODUCTS:
            return {
                ...state,
                md03UpgradeProducts: action.payload
            };

        case actionTypes.UPDATE_MD03_PRODUCT:
            return {
                ...state,
                md03Products: action.payload
            };

        case actionTypes.UPDATE_MD03_MEMBERSHIP:
            return {
                ...state,
                isMd03Member: action.payload
            };

        case actionTypes.UPDATE_PLAN_MODAL:
            return {
                ...state,
                quizModal: action.payload
            };

        case actionTypes.UPDATE_PLAN_MODAL_TYPE:
            return {
                ...state,
                quizModalType: action.payload
            };
        default:
            return state;
    }
}

// Context Provider
export const QuizCartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    // Utility functions to dispatch actions
    const addProduct = (product) => {
        if (state.isMd03Member) {
            dispatch({ type: actionTypes.ADD_PRODUCT, payload: product });
        } else {
            const isMd03Product = state.md03Products.filter(item => product.product_id === item.product_id)
            if (isMd03Product && isMd03Product.length > 0) {
                updateQuizModal(true)
                updateQuizModalType(QUIZ_PLAN_MODAL_TYPES.WELCOME)
                // Add the product
                dispatch({ type: actionTypes.ADD_PRODUCT, payload: product });
                // Update MD03 Membership
                dispatch({ type: actionTypes.UPDATE_MD03_MEMBERSHIP, payload: true })
            } else {
                dispatch({ type: actionTypes.ADD_PRODUCT, payload: product });
            }
        }
    };

    const removeProduct = (id) => {
        const isMd03ProductId = state.md03Products.filter(item => item.variant_id === id)
        if (isMd03ProductId && isMd03ProductId.length > 0) {
            const md03ProductIds = new Set(state.md03Products.map(product => product.variant_id));
            const presentMd03Product = state.products.filter(item => md03ProductIds.has(item.variant_id))
            if (presentMd03Product && presentMd03Product.length > 1) {
        // More than 1 MD03 products are present
                dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
            } else {
                updateQuizModal(true)
                updateQuizModalType(QUIZ_PLAN_MODAL_TYPES.DOWNGRADE)
            }

        } else {
            dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
        }
    };

    const updateMD03Membership = (products) => {
        const isMember = checkMd03Membership(products)
        dispatch({ type: actionTypes.UPDATE_MD03_MEMBERSHIP, payload: isMember })
    }

    const updateMD03Products = (products) => {
        dispatch({ type: actionTypes.UPDATE_MD03_PRODUCT, payload: products })
        updateMD03Membership(products)
    }


    const checkMd03Membership = (products) => {
        if (products && products.length > 0) {
            return true
        }
        return false
    }

    const isMD03Product = (product) => {
        const md03ProductIds = state.md03Products.map(product => product.variant_id);
        const presentMd03Product = md03ProductIds.filter(item => item === product.variant_id)
        if (presentMd03Product && presentMd03Product.length > 0) {
            return true
        }
        return false
    }

    const removeLastMd03Product = () => {
        const md03ProductIds = new Set(state.md03Products.map(product => product.variant_id));

        const presentMd03Product = state.products.filter(item => md03ProductIds.has(item.variant_id))
        if (presentMd03Product && presentMd03Product.length > 0) {
            const id = presentMd03Product[0].variant_id
            // Remove the last MD03 product
            dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
            // Update MD03 Membership
            dispatch({ type: actionTypes.UPDATE_MD03_MEMBERSHIP, payload: false })
            updateQuizModal(false)
        }
    }

    const updateQuizModal = (modalState) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_MODAL, payload: modalState });
    };

    const updateQuizModalType = (modalType) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_MODAL_TYPE, payload: modalType });
    };

    return (
        <QuizCartContext.Provider
            value={{
                products: state.products,
                total: state.total,
                isMd03Member: state.isMd03Member,
                md03Products: state.md03Products,
                quizModal: state.quizModal,
                quizModalType: state.quizModalType,
                addProduct,
                updateQuizModal,
                updateQuizModalType,
                removeProduct,
                updateMD03Products,
                removeLastMd03Product,
                isMD03Product,
            }}
        >
            {children}
        </QuizCartContext.Provider>
    );
};

// Custom hook to use cart context
export const useQuizCart = () => useContext(QuizCartContext);
