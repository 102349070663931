import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import QuizSlider from '../QuizSlider/QuizSlider'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'
import AreaButton from '../AreaButton/AreaButton'



const NOT_KNOWN_BUTTON_CONFIG = {
  key: 'not know',
  text: `I DON'T USE A MOISTURIZER`,
  backgroundColor: '#2E5ACC',
  color: '#FFFFFF',
}

// Validation schema
const validationSchema = Yup.object().shape({
  skinMoisturizer: Yup.number().min(0, `Please select a value on the scale or click on I DON’T USE MOISTURIZER button`).max(10).required('Please rate the gentleness of your cleanser'), // Ensure a value is selected
});

function PHQSkinMoisturizer() {
  const Que = getQuestionByQueId(queConstants.phq_skin_moisturizer)
  const moisturizerAns = useSelector(state => state.quizData[queConstants.phq_skin_moisturizer])
  const dispatch = useDispatch();

  const { control, reset, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      skinMoisturizer: moisturizerAns || 0, // Default to existing answer or 0
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.skinMoisturizer) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.skinMoisturizer])

  const handleNotKnownButton = () => {
    dispatch(updateQuizData({ queID: queConstants.phq_skin_moisturizer, ans: 0 }))
    reset({ skinMoisturizer: 0 })
  }


  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
        <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>How protective is your moisturizer? </div>
            <div className='PHQMessage'>■ click on the scale below <br />
              CLICK "I DON'T USE A MOISTURIZER" IF YOU DO NOT CURRENTLY USE A MOISTURIZER</div>
            <div className='PHQSpacer'></div>
            <Controller
              name="skinMoisturizer"
              control={control}
              render={({ field }) => (
                <QuizSlider
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_skin_moisturizer, ans: value }))
                  }}
                  leftText='It’s a flimsy shield; admittedly, I may need to upgrade!'
                  rightText='It’s a superhero fortress—my skin’s best friend and protector!'
                  domId={'moisturizer'}
                  gradient='linear-gradient(90deg, #25A18E 16%, #ABBDEA 100%)'
                />
              )}
            />
            <div className='sliderButton'>
            <AreaButton
              key={NOT_KNOWN_BUTTON_CONFIG.key} // Use a unique key for each button
              area={NOT_KNOWN_BUTTON_CONFIG.key}
              isSelected={moisturizerAns === 0}
              onClick={() => handleNotKnownButton()}
              backgroundColor={NOT_KNOWN_BUTTON_CONFIG.backgroundColor}
              color={NOT_KNOWN_BUTTON_CONFIG.color}
            >
              {NOT_KNOWN_BUTTON_CONFIG.text}
            </AreaButton>
            </div>
            <p className="PHQErrorMessage sliderError" id="errorMessage">
              {errors?.skinMoisturizer && (
                <span>{errors?.skinMoisturizer.message}<br /></span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQSkinMoisturizer