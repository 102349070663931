import PHQCareer from "@ph/common/components/Quiz/Questions/PHQCareer"
import PHQGender from "@ph/common/components/Quiz/Questions/PHQGender"
import PHQName from "@ph/common/components/Quiz/Questions/PHQName"
import PHQHallmarks from '@ph/common/components/Quiz/Questions/PHQHallmarks'
import PHQFaceIssues from "@ph/common/components/Quiz/Questions/PHQFaceIssues"
import { queConstants } from "@ph/common/constants/QuizConstants"
import PHQFaceArea from "../components/Quiz/Questions/PHQFaceArea"
import PHQBodyIssues from "../components/Quiz/Questions/PHQBodyIssues"
import PHQBodyBlemish from "../components/Quiz/Questions/PHQBodyBlemish"
import PHQBodyOdor from "../components/Quiz/Questions/PHQBodyOdor"
import PHQSkinCleanser from "../components/Quiz/Questions/PHQSkinCleanser"
import PHQSkinMoisturizer from "../components/Quiz/Questions/PHQSkinMoisturizer"
import PHQDoctorSkincare from "../components/Quiz/Questions/PHQDoctorSkincare"
import PHQSkincareAdventure from "../components/Quiz/Questions/PHQSkincareAdventure"
import PHQAgingProcess from "../components/Quiz/Questions/PHQAgingProcess"
import PHQSkincarePamper from "../components/Quiz/Questions/PHQSkincarePamper"
import PHQSkincareCuriosity from "../components/Quiz/Questions/PHQSkincareCuriosity"



export const questions = [
    {
        queId: queConstants.phq_name,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_email,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_age,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_zipcode,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_gender,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_ethnicity,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_hallmarks,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_face_issues,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_face_area,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_body_blemish_area,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_body_issues,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_body_odor_area,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_cleanser_gentleness,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skin_moisturizer,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_doctor_skincare,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skincare_adventure,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_aging_process,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skin_pamper,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skincare_curiosity,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_parallel,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_most_intrested_in,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_primary_focus,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_anxious,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_current_skin_concerns,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_morning_skin_feel,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skin_blemishes_flares_at,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_confidence_level,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skin_type,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_skin_health_journey,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_rx_free,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_sun_frequency,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_sunscreen_frequency,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_water_per_day,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_parent_skin,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_sleep_hygiene,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_diet_fruit,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_diet_vegetabels,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_diet_grains,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_diet_protien_foods,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_diet_dairy,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_current_career,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_workstress,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_screen_time,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_international_travel_frequency,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_anti_aging_products,
        isDestructive: false,
        value: null
    },
    {
        queId: queConstants.phq_when_it_comes_to_parallel,
        isDestructive: false,
        value: null
    },
]

export const pageRoutes = [
    {
    label: 'Basic',
    key: 'basic',
    order: 1,
    pages: [
        {
            path: 'name',
            questions: [queConstants.phq_name, queConstants.phq_email, queConstants.phq_age, queConstants.phq_zipcode],
            order: 1,
            component: <PHQName />
        },
        {
            path: 'gender',
            questions: [queConstants.phq_gender, queConstants.phq_ethnicity],
            order: 2,
            component: <PHQGender />
        },
        {
            path: 'career',
            questions: [queConstants.phq_current_career, queConstants.phq_workstress],
            order: 3,
            component: <PHQCareer />
        },
        ]
    },
    {
    label: 'Goals',
    key: 'goals',
    order: 2,
    pages: [
        {
            path: 'hallmarks',
            questions: [queConstants.phq_hallmarks],
            order: 4,
            component: <PHQHallmarks />
        },
        ]
    },
    {
        label: 'Face',
        key: 'face',
        order: 3,
        pages: [

        {
            path: 'face_area',
            questions: [queConstants.phq_face_area],
            order: 5,
            component: <PHQFaceArea />
        },

        {
            path: 'face_issue',
            questions: [queConstants.phq_face_issues],
            order: 6,
            component: <PHQFaceIssues />
        },
        ]
    },
    {
        label: 'Blemish',
        key: 'blemish',
        order: 4,
        pages: [
        {
            path: 'body_blemish',
            questions: [queConstants.phq_body_blemish_area],
            order: 7,
            component: <PHQBodyBlemish />
        },
        {
            path: 'body_issues',
            questions: [queConstants.phq_body_issues],
            order: 8,
            component: <PHQBodyIssues />
        },
        ]
    },
    {
        label: 'Odor',
        key: 'odor',
        order: 5,
        pages: [
        {
            path: 'body_odor',
            questions: [queConstants.phq_body_odor_area],
            order: 9,
            component: <PHQBodyOdor />
        },
        ]
    },
    {
        label: 'Skincare',
        key: 'skincare',
        order: 6,
        pages: [
        {
            path: 'cleanser_gentleness',
            questions: [queConstants.phq_cleanser_gentleness],
            order: 10,
            component: <PHQSkinCleanser />
        },
        {
            path: 'moisturizer',
            questions: [queConstants.phq_skin_moisturizer],
            order: 11,
            component: <PHQSkinMoisturizer />
        },
        {
            path: 'doctor_skincare',
            questions: [queConstants.phq_doctor_skincare],
            order: 12,
            component: <PHQDoctorSkincare />
        },
    ]
},
    // {
    //     label: 'Lifestyle',
    //     key: 'lifestyle',
    //     order: 7,
    //     pages: [
    //         {
    //             path: 'sun_frequency',
    //             questions: [queConstants.phq_sun_frequency],
    //             order: 14,
    //             component: <PHQSunFrequency />
    //         },
    //         {
    //             path: 'sun_screen_frequency',
    //             questions: [queConstants.phq_sunscreen_frequency],
    //             order: 15,
    //             component: <PHQSunScreenFrequency />
    //         },
    //         // {
    //         //     path: 'water_per_day',
    //         //     questions: [queConstants.phq_water_per_day],
    //         //     order: 15,
    //         //     component: <PHQWaterPerDay />
    //         // },
    //         {
    //             path: 'parent_skin',
    //             questions: [queConstants.phq_parent_skin],
    //             order: 16,
    //             component: <PHQParentSkin />
    //         },
    //         {
    //             path: 'sleep_hygienie',
    //             questions: [queConstants.phq_sleep_hygiene],
    //             order: 17,
    //             component: <PHQSleepHygienie />
    //         },
    //         {
    //             path: 'diet',
    //             questions: [queConstants.phq_diet_fruit, queConstants.phq_diet_vegetabels, queConstants.phq_diet_grains, queConstants.phq_diet_protien_foods, queConstants.phq_diet_dairy,],
    //             order: 18,
    //             component: <PHQDiet />
    //             // component:
    //         },

    //         {
    //             path: 'screen_time',
    //             questions: [queConstants.phq_screen_time],
    //             order: 20,
    //             component: <PHQScreenTime />
    //         },
    //         {
    //             path: 'international_travel_frequency',
    //             questions: [queConstants.phq_international_travel_frequency],
    //             order: 21,
    //             component: <PHQInternationalTravelFrequency />
    //         },
    //         {
    //             path: 'anti_aging_products',
    //             questions: [queConstants.phq_anti_aging_products],
    //             order: 22,
    //             component: <PHQAntiAgingProducts />
    //         },
    //         {
    //             path: 'when_it_comes_to_parallel',
    //             questions: [queConstants.phq_when_it_comes_to_parallel],
    //             order: 23,
    //             component: <PHQWhenComeToParallel />
    //         },
    //     ]
    //     },

    {
        label: 'adventure',
        key: 'adventure',
        order: 7,
        pages: [
            {
                path: 'skincare_adventure',
                questions: [queConstants.phq_skincare_adventure],
                order: 13,
                component: <PHQSkincareAdventure />
            },
            {
                path: 'aging_process',
                questions: [queConstants.phq_aging_process],
                order: 14,
                component: <PHQAgingProcess />
            },
            {
                path: 'skin_pamper',
                questions: [queConstants.phq_skin_pamper],
                order: 15,
                component: <PHQSkincarePamper />
                // component: 
            },
        ]
    },
    {
        label: 'Final',
        key: 'final',
        order: 8,
        pages: [
            {
                path: 'skincare_curiosity',
                questions: [queConstants.phq_skincare_curiosity],
                order: 16,
                component: <PHQSkincareCuriosity />
            },
            // {
            //     path: 'parallel',
            //     questions: [queConstants.phq_parallel],
            //     order: 17,
            //     component: <PHQParallel />
            // },
        ]
    },
]

export const pageSections = () => {
    return pageRoutes.map(section => {
        return {
            label: section.label,
            description: section.order,
            key: section.key,
            order: section.order,
        }
    }).sort((a, b) => a.order - b.order)
}

const lsKey = 'ph_quiz'

export const processBranching = (list, currentObj) => {
    return questions
}

export const getQuestionByQueId = (queId) => {
    //  get single question from question array
    // return question object or undefined

    const que = questions.filter(que => que.queId === queId)[0]
    if (!que) return null
    for (let i = 0; i < pageRoutes.length; i++) {
        const section = pageRoutes[i]
        const pageDetail = section.pages.filter(page => page.questions.includes(queId))[0]
        if (pageDetail) {
            que.route = pageDetail.path
            que.order = pageDetail.order
            break;
        }
    }
    return que
}

export const getNextPrevPage = (order, next = true) => {
    //  get single question from question array
    // return question object or undefined
    let obj
    order = Number(order) + 1
    if (!next) {
        order = order - 2
    }
    for (let i = 0; i < pageRoutes.length; i++) {
        const section = pageRoutes[i]
        const pageDetail = section.pages.filter(page => Number(page.order) === order)[0]
        if (pageDetail) {
            obj = {}
            obj.route = pageDetail.path
            obj.order = pageDetail.order
        }
    }
    return obj
}

export const getFirstPageOfOrder = (orderNo) => {
    // if order no is not in page routes return first page
    const baseRoute = 'quiz/name'
    if (orderNo > pageRoutes.length) {
        return baseRoute
    } else {
        const order = pageRoutes.filter(item => item.order === orderNo)
        if (order) {
            return order[0]?.pages[0] ?? baseRoute
        }
    }
    return baseRoute
}

export const getLS = (queId = null) => {
    // get localstorage by queId
    // below object will be stored into the array
    // {
    //   id: '1',
    //   queId: 'phq_name',
    //   value: 'Test Name',
    //   index: 0  // this is the sequence of question answers is given
    // }
    // if queId given then it will return object for particular question queId, else array of all questions stored in local storage
    const lsData = localStorage.getItem(lsKey)
    if (!lsData) return null
    const data = JSON.parse(lsData)
    if (queId) {
        return data.filter(que => que.queId === queId)[0]
    }
    return data
}

export const saveQueLs = (queObj) => {
    const data = getLS() || [] // get existing list from local storage
    const index = data.findIndex(que => { // get given question index to update the details
        return que.queId === queObj.queId
    })
    if (index) {
        data[index] = {
            ...queObj,
        }
    } else {
        data.push({
            ...queObj,
            index: findMaxIndex(data)
        })
    }
    replaceLS(data)
}

export const replaceLS = (queArr) => {

    localStorage.setItem(lsKey, queArr)
    return getLS()
    // it will replace existing data of local storage to new one
}

export const findMaxIndex = (queArr) => { // if we already have list from local storage then pass it here, if not then it will fetch from local storage
    // this return maximum index from local storage
    let list = queArr
    if (!queArr) {
        list = getLS()
    }
    if (Array.isArray(list) && list.length > 0) {
        return Math.max(...list.map(l => l.index))
    }
    return 1
    // logic to find the max index
    // return next index
}

export const getHistory = (index, queArr) => { // for given index it will provide history, if we pass 3 then 1,2,3 index containing data will be return
    let list = queArr
    if (!queArr) {
        list = getLS()
    }
    return list.filter(que => que.index <= index) // array of local storage question object
}

export const findNextPrev = (queObj, next = true) => {
    let list = getLS()
    let tmpObj
    if (next) {
        tmpObj = list.filter(que => que.index === queObj.index + 1)[0] || null
    } else {
        tmpObj = list.filter(que => que.index === queObj.index - 1)[0] || null
    }
    if (tmpObj) {
        return getQuestionByQueId(tmpObj.queId)
    }
    return null
}

export const prevNavigate = (currentObj) => {
    return getNextPrevPage(currentObj.order, false)
    return findNextPrev(currentObj, false)
}

export const nextNavigate = (currentObj) => {
    // below actions are taken here

    // fetch answer of given question queId from ls
    // if answer not exist then find next index and store question with answer and index into ls
    // if answer exist, Compare component answer with answer stored in ls
    // If changes Is not detect then it will just take to the next page base on the indexing stored in ls
    // If changes detect and question is destructive then ls data will be replaced
    // If changes is detect and question is not destructive then value will be updated in ls
    // After that it will recalculate conditional branching, and base on output of the index on ls will be updated and it will goes to the next page

    const lsValue = getLS(currentObj.queId)
    if (!lsValue) {
        saveQueLs(currentObj)
        const list = getLS()
        // const pbData = processBranching(list, currentObj) // assuming required to pass question and answer array as a argument
        // update the groupData percentage based on above result, assuming whole group data will be returned
        // questionGroup = pbData.questionGroup
        // return pbData.queObj // assuming this will return next question data
    }

    return getNextPrevPage(currentObj.order)

    const isChanged = currentObj.value !== lsValue.value
    if (!isChanged) {
        return findNextPrev(currentObj, true)
    }
    if (isChanged) {
        if (currentObj.isDestrictive) {
            const newArr = getHistory(currentObj.index, null)
            replaceLS(newArr)
        }
        const list = getLS()
        const pbData = processBranching(list, currentObj) // assuming required to pass question and answer array as a argument
        // update the groupData percentage based on above result, assuming whole group data will be returned
        // questionGroup = pbData.questionGroup
        return pbData.queObj // assuming this will return next question data
    }
    console.log(lsValue)
    return lsValue
}

export const validateData = (data) => {
    if (data) data = data.trim()
    return !!data
}


export const getParentOrderByPath = (path) => {
    for (const route of pageRoutes) {
        for (const page of route.pages) {
            if (page.path === path) {
                return route.order; // Return the order of the parent
            }
        }
    }
    return null; // Return null if the path is not found
}

export const getParentOrderByChildOrder = (order) => {
    for (const route of pageRoutes) {
        for (const page of route.pages) {
            if (page.order === order) {
                return route.order; // Return the order of the parent
            }
        }
    }
    return null; // Return null if the path is not found
}