import React, {useEffect, useState} from "react";
import {Col, Row, Space, Typography} from "antd";
import { facebook, instagram, linked_in, PH_logo_light_tm, youtube } from "@ph/common/assets";
import style from "./footer.module.scss";
import {useLocation} from "react-router-dom";
import {buildCompleteShopifyLink} from "@ph/common/global/Helpers";
import { SHOPIFY_PAGES } from "@ph/common/constants/ShopifyPages";

const { Text, Link } = Typography;

const Footer = () => {
    let location = useLocation();
    const [currentPath, setcurrentPath] = useState(location.pathname);

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    // useEffect(() => {
    //     $(window).scroll(function () {
    //         if (!sticky_closed) {
    //             var docScroll = $(document).scrollTop();
    //             if (docScroll <= 20) {
    //                 $("#PHfooter").hide();
    //             } else {
    //                 $("#PHfooter").show();
    //             }
    //         }
    //     });
    // });

    return (
        !(currentPath.toString().includes('getstarted')) ?
            <footer className={style.footerContainer}>
                {/* <div className="klaviyo-form-Xvm5mJ klaviyo-subscribe-form"></div> */}
                <div className={style.bigFooter}>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} xl={24} className={style.logoSection}>
                            {/* <Text className={style.appName}>parallel</Text> */}
                            <img src={PH_logo_light_tm} alt="Parallel" />
                        </Col>
                        <Col xs={24} sm={18} md={18} xl={18} className={style.logoSection}>
                            <div className="body-txt-md text-light">
                                <a href="https://www.parallelhealth.io" className="td-underline">www.parallelhealth.io</a> is the consumer division of Parallel Health, a precision health platform leveraging genomic sequencing and next-generation phage therapy to improve human, animal, and environmental health.
                            </div>
                        </Col>
                        {/* <Col xs={24} sm={8} className={style.logoSection} style={{ justifyContent: 'end' }}>
                            <h4 className="text-light">Skin science in your inbox</h4>
                        </Col> */}
                        <Col xs={24} sm={24} md={24} xl={24} className={style.logoSection}>
                            <FooterLayout />
                        </Col>

                        {/* <Col xs={24} sm={4} md={4} xl={4} className={style.navSection}>
                            <div className={style.footerTabContainer}>
                                <div className={style.footerTab} style={{ marginLeft: 0 }}>
                                    <Link href={process.env.REACT_APP_SHOPIFY_URL}
                                          className={style.footerTabLink}>
                                        <Text className={style.text}>PRODUCTS</Text>
                                    </Link>
                                    <Link href={buildCompleteShopifyLink('pages/science')}
                                          className={style.footerTabLink}>
                                        <Text className={style.text}>SCIENCE</Text>
                                    </Link>
                                    <Link href={buildCompleteShopifyLink('pages/our-team')}
                                          className={style.footerTabLink}>
                                        <Text className={style.text}>LEARN</Text>
                                    </Link>
                                    <Link className={style.footerTabLink}
                                          href={buildCompleteShopifyLink('a/faq')}>
                                        <Text className={style.text}> FAQ </Text>
                                    </Link>
                                </div>
                            </div>
                        </Col> */}
                        {/* <Col xs={24} sm={4} md={4} xl={4} className={style.buttonSection}>
                            <Button className={style.questionButton}
                                    onClick={() => window.open(`mailto:hello@parallelhealth.io`, '_blank')}>Any questions?</Button>
                        </Col> */}
                    </Row >
                    <div className={style.policySection}>
                        <Row className="hide">
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ order: 1, span: 24 }} lg={{ order: 1, span: 24 }}>
                                <div className={style.separator}></div>
                            </Col>
                            <Col xs={{ span: 24, order: 4 }} sm={{ span: 24, order: 4 }} md={{ order: 2, span: 24 }} lg={{ order: 2, span: 8 }} className={style.URLSection}>
                                <Link href='https://www.parallelhealth.io'>
                                    <Text className={style.text}>©WWW.PARALLELHEALTH.IO</Text>
                                </Link>
                            </Col>
                            <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 24 }} md={{ order: 3, span: 24 }} lg={{ order: 3, span: 8 }} className={style.socialIconSection}>
                                <Space>
                                    {/* <InstagramOutlined className={style.icon} />
                                    <FacebookOutlined className={style.icon} />
                                    <YoutubeFilled className={style.icon} /> */}
                                    <img src={instagram} className={`${style.icon} hand-cursor`} alt="insta"
                                         onClick={() => window.open('https://www.instagram.com/yourtrueparallel', '_blank')}/>
                                    <img src={facebook} className={`${style.icon} hand-cursor`} alt="fb"
                                         onClick={() => window.open('https://www.facebook.com/yourtrueparallel', '_blank')}/>
                                    <img src={youtube} className={`${style.icon} hand-cursor`} alt="yt"
                                         onClick={() => window.open('https://www.youtube.com/@parallel8494', '_blank')}/>
                                    <img src={linked_in} className={`${style.icon} hand-cursor`} alt="linked_in"
                                         onClick={() => window.open('https://www.linkedin.com/company/parallelhealth', '_blank')}/>       
                                </Space>
                            </Col>
                            <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} md={{ order: 4, span: 24 }} lg={{ order: 4, span: 8 }} className={style.linkSection}>
                                <Link className={style.footerLink} target='_blank'
                                      href={buildCompleteShopifyLink('pages/terms-of-service')}>
                                    <Text className={style.text}>USER TERMS AND CONDITIONS</Text>
                                </Link>
                                <Link className={style.footerLink} target='_blank'
                                      href={buildCompleteShopifyLink('pages/privacy-policy')}>
                                    <Text className={style.text}>COOKIE USE POLICY</Text>
                                </Link>
                                <Link className={style.footerLink} target='_blank'
                                      href={buildCompleteShopifyLink('pages/disclaimer')}>
                                    <Text className={style.text}>DISCLAIMER</Text>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* <div className={style.smallFooter}>
                    <div id="PHfooter">
                        <Space className={style.footerSpace} split={<Divider type="vertical" className={style.separator} />}>
                            <div className={style.footerBlock} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`}>
                                <img src={home_dark} className={style.footerIcon} alt="home" />
                                <Text className={style.footerText}>Home</Text>
                            </div>
                            <div className={style.footerBlock} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/collections/all`}>
                                <img src={grid_icon} className={style.footerIcon} alt="shop" />
                                <Text className={style.footerText}>Shop</Text>
                            </div>
                            <div className={style.footerBlock} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/cart`}>
                                <img src={shopify_icon} className={style.footerIcon} alt="cart" />
                                 <Text className={style.footerText}>Cart</Text>
                            </div>
                            <div className={style.footerBlock} onClick={() => window.location = `/logout`}>
                                <img src={user_icon} className={style.footerDashboardIcon} alt="dashboard" />
                                <Text className={style.footerText + " " + style.footerTextActive}>Dashboard</Text>
                            </div>
                        </Space>
                    </div>
                </div> */}
            </footer>
            : <></>
    );
};

export default Footer;


const FooterLayout = () => {
    return (
        <Row className="full-width">
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ order: 1, span: 24 }} lg={{ order: 1, span: 24 }}>
                <div className={style.separator}></div>
            </Col>
            <Col xs={{ span: 24, order: 4 }} sm={{ span: 24, order: 4 }} md={{ order: 2, span: 24 }} lg={{ order: 2, span: 24 }} className={style.URLSection}>
                <Row className="full-width">
                    <Col xs={24} sm={9} md={9} className={`${style.socialIconSection} show-only-on-desktop`}>
                        <div class="klaviyo-form-Xvm5mJ klaviyo-form-new"></div>
                        <Space className={style.socialContainer}>
                            {/* <InstagramOutlined className={style.icon} />
                                    <FacebookOutlined className={style.icon} />
                                    <YoutubeFilled className={style.icon} /> */}
                            <img src={instagram} className={`${style.icon} hand-cursor`} alt="insta"
                                onClick={() => window.open('https://www.instagram.com/yourtrueparallel', '_blank')} />
                            <img src={facebook} className={`${style.icon} hand-cursor`} alt="fb"
                                onClick={() => window.open('https://www.facebook.com/yourtrueparallel', '_blank')} />
                            {/* <img src={youtube} className={`${style.icon} hand-cursor`} alt="yt"
                                onClick={() => window.open('https://www.youtube.com/@parallel8494', '_blank')} /> */}
                            <img src={linked_in} className={`${style.icon} hand-cursor`} alt="linked_in"
                                onClick={() => window.open('https://www.linkedin.com/company/parallelhealth', '_blank')} />
                        </Space>

                        <p className={`${style.footerNav} body-txt-md text-light`}>© Parallel Health 2024</p>
                    </Col>
                    <Col xs={24} sm={15} md={15}>
                        <Row className="full-width">
                            <Col xs={12} sm={6} md={6} className={style.linkContainer}>
                                <p className="note-1 text-light font-Mono-medium"> PRODUCTS</p>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}>
                                    <p className={style.textlink}> MD-03 Protocol™</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.PERSONALIZED_PRESCRIPTION)}>
                                    <p className={style.textlink}> Personalized Prescription</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.CLEANSER)}>
                                    <p className={style.textlink}> Holy Calming Cleanser</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.MOISTURISER)}>
                                    <p className={style.textlink}> Skin Barrier Silk Cream</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.SKIN_MICROBIOME_DISCOVERY_KIT)}>
                                    <p className={style.textlink}> Skin Discovery Test</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.BODY_BLEMISH_TEST_KIT)}>
                                    <p className={style.textlink}> Body Blemish Test</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.BODY_BLEMISH_ODOR_KIT)}>
                                    <p className={style.textlink}> Odor Discovery Test</p>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={style.linkContainer} >
                                <p className="note-1 text-light font-Mono-medium"> COMPANY</p>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.TEAM)}>
                                    <p className={style.textlink}> Team</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.FAQ)}>
                                    <p className={style.textlink}> Faq</p>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={style.linkContainer}>
                                <p className="note-1 text-light font-Mono-medium"> SCIENCE</p>                               
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.CLINICIANS)}>
                                    <p className={style.textlink}> For Clinicians</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.REASEARCH)}>
                                    <p className={style.textlink}> Research and Studies</p>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={style.linkContainer}>
                                <p className="note-1 text-light font-Mono-medium"> SUPPORT</p>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.POLICY)}>
                                    <p className={`${style.textlink}`}> Privacy Policy</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.TERMS)}>
                                    <p className={`${style.textlink}`}> Terms of Service</p>
                                </Link>
                                <Link target='_blank' href={buildCompleteShopifyLink(SHOPIFY_PAGES.DISCLAIMER)}>
                                    <p className={`${style.textlink}`}>Disclaimer</p>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={8} md={8} className={`${style.socialIconSection} hide-on-desktop`} style={{ marginTop: '26px' }}>

                        <Space className={style.socialContainer}>
                            {/* <InstagramOutlined className={style.icon} />
                                    <FacebookOutlined className={style.icon} />
                                    <YoutubeFilled className={style.icon} /> */}
                            <img src={instagram} className={`${style.icon} hand-cursor`} alt="insta"
                                onClick={() => window.open('https://www.instagram.com/yourtrueparallel', '_blank')} />
                            <img src={facebook} className={`${style.icon} hand-cursor`} alt="fb"
                                onClick={() => window.open('https://www.facebook.com/yourtrueparallel', '_blank')} />
                            {/* <img src={youtube} className={`${style.icon} hand-cursor`} alt="yt"
                                onClick={() => window.open('https://www.youtube.com/@parallel8494', '_blank')} /> */}
                            <img src={linked_in} className={`${style.icon} hand-cursor`} alt="linked_in"
                                onClick={() => window.open('https://www.linkedin.com/company/parallelhealth', '_blank')} />
                        </Space>

                        <p className={`${style.footerNav} body-txt-md text-light`}>© Parallel Health 2024</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
