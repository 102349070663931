import React, { useEffect, useState } from 'react'
import style from './quizCart.module.scss'
import { quiz_close, button_arrow } from "@ph/common/assets/index";
import { Drawer, Button, Typography, Grid, Collapse } from 'antd'
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import { convertPrice } from '@ph/common/global/Helpers';
import MD03UpgradeModal from '../../Report/ReportContainer/MD03UpgradeModal';
import { QUIZ_PLAN_MODAL_DETAILS, MD03_LABEL } from '@ph/common/constants/QuizConstants';

const { Text } = Typography

const { useBreakpoint } = Grid


function QuizCart() {
    const [openCart, setOpenCart] = useState(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const { products, quizModal, quizModalType, updateQuizModal, removeLastMd03Product, isMd03Member, isMD03Product } = useQuizCart()     
    const screens = useBreakpoint();
    const isMobile = screens.xs
    const handleDrawerClick = () => {
        setOpenCart(!openCart)
    }
    const getVariantUrl = (productArr) => {
        const variant_id_key = isMd03Member ? 'variant_id' : 'ala_cart_variant_id'
        const selling_plan_key = isMd03Member ? 'selling_plan_id' : 'ala_cart_selling_plan_id'
        let url = ''
        productArr.forEach(variant => {
            if (variant[selling_plan_key]) {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26`
            }
        })
        return url
    }

    const calculateTotal = () => {
        let sum = 0
        if (products && products.length > 0) {
            products.map(product => {
                if (isMd03Member) {
                    sum += parseFloat(product?.price)
                } else {
                    sum += parseFloat(product?.ala_cart_price)
                }
            })
        }
        return sum
    }

    function createCartLink(domain, products) {
        const baseUrl = `https://${domain}/cart/clear?return_to=/cart/add?`;
        return `${baseUrl}${getVariantUrl(products)}return_to=/checkout`;
    }

    const handleAddToCart = () => {
        setLoading(true)
        const cartLink = createCartLink(process.env.REACT_APP_REPORT_SHOPIFY_DOMAIN, products)
        window.location.href = cartLink;

    }

    useEffect(() => {
        const tempPrice = calculateTotal(products)
        setTotal(tempPrice)
    }, [products])
    return (
        <>
            {/* <div className={style.cartIcon} onClick={handleDrawerClick}>
                {!openCart ? (
                    <img src={shopify_icon_light} alt='cart' className={style.icon} />
                ) : (<svg className={`${style.icon} ${style.arrowIcon}`} width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.34744 26.7358C8.69488 27.0976 9.27394 27.0976 9.62138 26.6755L20.7394 14.1331C21.0869 13.7713 21.0869 13.2287 20.7394 12.8669L9.62138 0.324545C9.27394 -0.0975513 8.69488 -0.0975513 8.34744 0.264246C7.94209 0.626043 7.94209 1.22904 8.28953 1.59084L18.8285 13.5302L8.28953 25.4092C8.11581 25.5901 8 25.8313 8 26.0725C8 26.3137 8.11581 26.5549 8.34744 26.7358Z" fill="white" />
                    <path d="M0.374165 26.7358C0.74833 27.0976 1.37194 27.0976 1.7461 26.6755L13.7194 14.1331C14.0935 13.7713 14.0935 13.2287 13.7194 12.8669L1.7461 0.324545C1.37194 -0.0975513 0.74833 -0.0975513 0.374165 0.264246C-0.0623608 0.626043 -0.0623608 1.22904 0.311804 1.59084L11.6615 13.5302L0.311804 25.4092C0.124722 25.5901 0 25.8313 0 26.0725C0 26.3137 0.124722 26.5549 0.374165 26.7358Z" fill="white" />
                </svg>
                )}
            </div> */}
            <Drawer
                open={openCart}
                onClose={handleDrawerClick}
                width={'600px'}
                height={'100vh'}
                placement={isMobile ? "bottom" : 'right'}
                closable={true}
                className={style.cartDrawer}
                closeIcon={<img src={quiz_close} alt='X' />}
            >
                <div className={style.cartContainer}>
                    <div className={style.cartProductWrap}>
                        <div className={style.cartItem}>
                            <Text className={style.cartTitle}>
                                PRODUCT RECOMMENDATIONS SUMMARY
                            </Text>
                        </div>
                        {products.map(item => (isMD03Product(item) ? <Md03CartProduct product={item} key={item.variant_id} /> :
                            <div className={style.cartItem} key={item.variant_id}>
                                <Text className={`${style.itemTitle} ${style.bold}`}>
                                    {item?.product_name}
                                </Text>
                                <Text className={style.itemPrice}>
                                    {`$${isMd03Member ? convertPrice(item?.price) : convertPrice(item?.ala_cart_price)}/Mo`}
                                </Text>
                            </div>
                        ))}
                        <div className={style.subTotal}>
                        </div>
                    </div>
                    <div className={style.total}>
                        <Text className={style.itemTitle}>
                            TOTAL
                        </Text>
                        <Text className={style.itemPrice}>
                            ${convertPrice(total)}/MO
                        </Text>
                    </div>
                </div>
                <div className={style.cartButtonContainer}>
                    <Button type="primary" className={style.addButton} onClick={handleAddToCart} loading={loading}> Proceed To Checkout <img src={button_arrow} className='ph-btn-arrow' /></Button>
                </div>
            </Drawer>
            <MD03UpgradeModal
                openModal={quizModal}
                setOpenModal={updateQuizModal}
                title={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.title}
                subTitle={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.subTitle}
                cancelText={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.cancelText}
                successText={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.successText}
                onCancel={() => removeLastMd03Product()}
                onSuccess={() => updateQuizModal(false)}
            />
            {!openCart && (

                <div className={style.floatButton}>
                    <div className={style.cartButtonContainer}>
                        <Button type="primary" className={style.addButton} onClick={handleDrawerClick}> Review Order <img src={button_arrow} className='ph-btn-arrow' /></Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default QuizCart

const Md03CartProduct = ({ product }) => {
    return (
        <>
            <Collapse
                size="small"
                items={[{
                    key: '1', label: <div className={`${style.cartItem} ${style.md03Item}`}>
                        <Text className={`${style.itemTitle} ${style.bold}`}>
                            MD-03 Protocol™ ({`${MD03_LABEL[product?.product_name]}`})
                        </Text>
                        <Text className={style.itemPrice}>
                            {`$${convertPrice(product?.price)}/Mo`}
                        </Text>
                    </div>, children: <>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                {product?.product_name}
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                DAILY RESET DUO: HOLY CALMING CLEANSER & SILK BARRIER SILK CREAM
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                SESSION WITH A PARALLEL MICROBIOME DERMATOLOGY™ DOCTOR (AGING)
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                SKIN HEALTH REPORT
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                CUSTOM ACTIVE PHAGE SERUM
                            </Text>
                        </div>
                    </>
                }]}
                className={style.customCollapse}
            />
            {/* <div className={style.cartItem}>
                <Text className={`${style.itemTitle} ${style.bold}`}>
                    MD-03 Protocol™
                </Text>
                <Text className={style.itemPrice}>
                    {`$${convertPrice(product?.price)}/Mo`}
                </Text>
            </div> */}

        </>
    )
}