import React from 'react';
import style from "./PHProtocol.module.scss";
import StepProducts from './StepProducts';
import { Button } from 'antd';
import { button_arrow, Back_Arrow_Border } from '@ph/common/assets/index';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';

function StepDetails({ activeTab, stepData, setActiveTab }) {

    const { currStep, updateCurrStep,
        updateToPreviousStep,
        updateToNextStep,
        protocolData } = useProtocolContext()

    return (
        <>
            <div className={`${style.tabContain} ${currStep === stepData.id ? style.active : ''}`}>
                <p className={style.stepStartLabel}>{stepData?.startLabel}</p>
                <h2 className='text-uv-blue font-NeueHGDP-light'>{stepData.title}</h2>
                <p className='body-txt-lg text-uv-blue'><div dangerouslySetInnerHTML={{ __html: stepData.description }}></div></p>
                {stepData?.selectionLabel && (
                    <p style={{ marginTop: '20px' }}><div dangerouslySetInnerHTML={{ __html: stepData.selectionLabel }} className='protocol-description'></div> </p>
                )}
                <StepProducts products={stepData.step_data} stepId={stepData.id} />
                {stepData?.footerLabel && (
                    <p style={{ marginTop: '20px' }}><div dangerouslySetInnerHTML={{ __html: stepData.footerLabel }} className='protocol-description'></div> </p>
                )}
            </div>

        </>
    )
}

export default StepDetails