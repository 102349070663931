import React, { useEffect } from 'react';
import style from './quizSlider.module.scss';
import { Grid } from 'antd';

const { useBreakpoint } = Grid

const QuizSlider = ({ value, onChange, leftText = '', rightText = '', domId = '', gradient = 'linear-gradient(to right, #6699cc, #66cccc)' }) => {
    const totalDots = 10;

    const screens = useBreakpoint();
    const isMobile = screens.xs

    const sliderDomID = `${domId}_slider`;
    const sliderDotsDomID = `${domId}_sliderDot`;


    useEffect(() => {
        if (value) {
            updateSlider(value - 1);
        } else {
            updateSlider(-1)
        }
    }, [value]);

    const extraPaddingArray = [76, 68, 52, 38, 24, 6, -10, -22, -36, 0]

    const extraPaddingArrayMobile = [70, 60, 44, 34, 18, 2, -10, -20, -32, 0]

    const updateSlider = (index) => {
        const gradient = document.querySelector(`.${sliderDomID}`);
        const dots = document.querySelectorAll(`.${sliderDotsDomID}`);
        const percentage = (index / (totalDots - 1)) * 100;
        const extraPadding = isMobile ? extraPaddingArrayMobile[index] : extraPaddingArray[index]
        if (index == -1) {
            gradient.style.width = `0px`;
        } else {
            gradient.style.width = `calc(${percentage}% + ${extraPadding}px) `;
        }
        dots.forEach((dot, i) => {
            dot.classList.toggle(style.active, i === index);
        });
    };

    const handleDotClick = (index) => {
        onChange(index + 1); // Pass the value (1-10)
    };

    return (
        <div className={`${style.sliderContainer}`}>
            <div className={style.slider}>
                <div
                    className={`${style.sliderGradient} ${sliderDomID}`}
                    style={{ background: gradient }}
                ></div>
                <div className={style.sliderDotsContainer}>
                    <div className={style.sliderDots}>
                        {[...Array(totalDots)].map((_, index) => (
                            <div
                                key={index}
                                className={`${style.sliderDotWrap} ${sliderDotsDomID}`}
                                onClick={() => handleDotClick(index)}
                            >
                                <div className={style.sliderDot}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={style.sliderLabels}>
                <span className={style.left}>01
                    <div className={style.sliderText}> <span className={style.leftText}>{leftText}</span> </div>
                </span>
                <span className={style.middle}>05
                    <div className={style.arrowContainer}>
                        <svg width="204" height="24" viewBox="0 0 204 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM203.061 13.0607C203.646 12.4749 203.646 11.5251 203.061 10.9393L193.515 1.3934C192.929 0.807611 191.979 0.807611 191.393 1.3934C190.808 1.97919 190.808 2.92893 191.393 3.51472L199.879 12L191.393 20.4853C190.808 21.0711 190.808 22.0208 191.393 22.6066C191.979 23.1924 192.929 23.1924 193.515 22.6066L203.061 13.0607ZM2 13.5H202V10.5H2V13.5Z" fill="#2D2926" />
                        </svg>
                    </div>
                </span>
                <span className={style.right}>10
                    <div className={`${style.sliderText} ${style.rightSide}`}> <span className={style.rightText}>{rightText}</span> </div>
                </span>
            </div>


        </div >
    );
};

export default QuizSlider;
