import { Steps, Divider, Grid } from 'antd';
import style from './quizProgressBar.module.scss';
import { pageSections } from '@ph/common/utils/quizUtils';

import { addLeadingZero } from '../../Report/reportUtils';
import { useNavigate } from 'react-router-dom';
import { getFirstPageOfOrder } from '@ph/common/utils/quizUtils';
import { useDispatch } from 'react-redux';
import { updateQuestionNumber } from '@ph/common/store/quizData.reducer';
const { useBreakpoint } = Grid;


const BarIcon = ({ className, order }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleOnClick = () => {
        if (order) {
            const page = getFirstPageOfOrder(order)
            // navigate(`/quiz/${page?.path}`)
            dispatch(updateQuestionNumber(page.order))

        }
    }

    return (
        <div className={`${style.halfCircle} ${className} barIcon`} onClick={handleOnClick}></div>
    )
}

function rotateArray(n, a) {
    // rotate array till n values
    if (n < 0 || n > a.length) {
        return "Invalid input: n must be between 0 and the length of the array.";
    }
    const removedItems = a.splice(0, n);
    a.push(...removedItems);

    return a;
}

const QuizProgressBar = ({ currentQue }) => {
    const screens = useBreakpoint();
    const isMobile = screens.xs

    let verticalLines = {
        icon: (<Divider className={style.verticalDivider} type="vertical" />),
        description: ""
    };
    let horizontalLines = {
        icon: (<Divider className={style.horizontalDivider} type="horizontal" />),
        description: ""
    };
    let statusIconObj = {
        icon: (<img alt='' className={style.stepIcon} src={BarIcon} />),
        description: "",
        className: "status-icon-container"
    };

    const arrowLeft = {
        icon: (<div className={`${style.arrow} ${style.arrowLeft}`} />),
        description: ''
    };

    const arrowRight = {
        icon: (<div className={`${style.arrow} ${style.arrowRight}`} />),
        description: ''
    };

    function itemsBuilder(inputIconsList, forHorizontalTracker) {
        let iconsList = inputIconsList
        if (isMobile) {
            if (currentQue === 3) {
                iconsList = rotateArray(1, inputIconsList)
            }
            if (currentQue === 4) {
                iconsList = rotateArray(2, inputIconsList)
            }
            if (currentQue === 5) {
                iconsList = rotateArray(3, inputIconsList)
            }
            if (currentQue >= 6) {
                iconsList = rotateArray(4, inputIconsList)
            }
        }
        let length = iconsList.length + ((iconsList.length + 1));
        let trackerLines = horizontalLines;
        if (forHorizontalTracker) {
            // length = ((iconsList.length + 1) * 2) + iconsList.length;
            trackerLines = verticalLines;
        }
        return Array.from({ length }, itemsBuilderMapper.bind(this, iconsList, forHorizontalTracker, trackerLines));
    }

    function itemsBuilderMapper(iconsList, forHorizontalTracker, trackerLines, _, i) {
        let next = (i + 1),
            iconIdx = (next / 2) - 1,
            setIcon = (next % 2 === 0);
        if (i === 0) {
            return { ...arrowLeft }
        }
        let length = iconsList.length + ((iconsList.length + 1));
        if (i === length - 1) {
            return { ...arrowRight }
        }
        if (setIcon) {
            const { description, order } = { ...iconsList[iconIdx] };
            let iconObj = { ...statusIconObj };
            const filled = order === currentQue
            if (filled) {
                iconObj.icon = (<BarIcon className={style.filled} order={order} />);
            } else {
                iconObj.icon = (<BarIcon className={style.empty} order={order} />);
            }

            iconObj.description = addLeadingZero(description);
            if (iconIdx === 0) {
                iconObj.className += ` first-icon`;
            }
            if (order > currentQue) {
                iconObj.className += ` next-icon`;
            }

            return { ...iconObj };
        }
        if ((i > 0 && i < length - 2)) {
            return { ...trackerLines, icon: <></> };
        }
        return { ...trackerLines };
    }

    return (
        <div className={style.progressContainer}>
        <div className={style.statusContent}>
            <div className={style.horizontalStepQuizProgress}>
                <Steps labelPlacement='vertical' responsive={false} current={currentQue * 2} className={style.step}
                    items={itemsBuilder(pageSections(), true)}>
                </Steps>
            </div>
        </div>
        </div>
    )
}

export default QuizProgressBar;