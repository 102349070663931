import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from "antd";
import ResultsTopContainer from './ResultsTopContainer';
import QuizCart from '../QuizCart/QuizCart';
import QuizProducts from './QuizProducts';
import QuizMD03 from '../QuizMD03/QuizMD03';
import { useDispatch, useSelector } from 'react-redux';
import { postQuizData } from '@ph/subject/src/api_services/api';
import { handleErrorMsg } from '@ph/common/global/Helpers';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants';
import { COPY_FLAGS } from '@ph/common/constants/QuizConstants';
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import { useParams } from 'react-router-dom';
import { getQuizDataById } from '@ph/subject/src/api_services/api';
import QuizCombineProducts from '../QuizProduct/QuizCombineProduct';

function QuizResult() {
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector((state) => state.userData.userData);
    const ans = useSelector(state => state.quizData);
    const [copyFlag, setCopyFlag] = useState(COPY_FLAGS.MD03)
    const [loading, setLoading] = useState(false)
    const [userName, setName] = useState('')
    const [recommendations, setRecommendations] = useState({})
    const { updateMD03Products, isMd03Member } = useQuizCart()
    const quizId = params.quizId 



    function isRecTrue() {
        // Get the current URL's search params
        const urlParams = new URLSearchParams(window.location.search);

        // Check if 'rec' parameter exists and its value is 'true'
        return urlParams.get('rec') === 'true';
    }

    const handleResultResponse = (rec) => {
        setRecommendations(rec)
        if (rec) {

            if (rec?.no_recommendation_tags) {
                setCopyFlag(COPY_FLAGS.NO_REC)
            } else if (!rec?.primary_recommendation) {
                setCopyFlag(COPY_FLAGS.PART_REC)
            }
            let md03ProductsRec = []
            if (rec?.primary_recommendation) {
                md03ProductsRec.push(rec.primary_recommendation)
            }
            if (rec?.secondary_recommendation) {
                md03ProductsRec.push(rec.secondary_recommendation)
            }
            updateMD03Products(md03ProductsRec.flat())
        }
    }

    useEffect(() => {
        const showAlreadyRec = isRecTrue()

        if (quizId) {
            setLoading(true)
            getQuizDataById(quizId).then(res => {
                if (res && res?.data) {
                    handleResultResponse(res.data[0]?.recommendations)
                    if (res.data[0]) {
                        const answers = res.data[0]?.answers
                        setName(answers?.name ?? '')

                    }
                }
            }).catch(error => handleErrorMsg(error, 'Something went wrong!'))
                .finally(() => setLoading(false))
        }

    }, [])

    const getAllProducts = () => {
        let allProducts = []
        if (recommendations?.primary_recommendation) {
            allProducts.push(recommendations?.primary_recommendation)
        }
        if (recommendations?.secondary_recommendation) {
            allProducts.push(recommendations?.secondary_recommendation)
        }
        if (recommendations?.foundational_care) {
            allProducts.push(recommendations?.foundational_care)
        }
        if (recommendations?.prescription) {
            allProducts.push(recommendations?.prescription)
        }
        if (recommendations?.daily_essential) {
            allProducts.push(recommendations?.daily_essential)
        }

        return allProducts.flat()
    }

    const getAllTags = () => {
        let allTags = []
        if (recommendations?.other_tags) {
            allTags = recommendations?.other_tags
        }
        if (recommendations?.primary_tags && recommendations?.primary_recommendation && recommendations?.primary_recommendation.length > 0) {
            const primaryProduct = recommendations?.primary_recommendation[0]
            allTags[primaryProduct.variant_id] = recommendations?.primary_tags.flat()
        }
        return allTags
    }

    if (loading) {
        return (
            <div className="text-center" style={{ padding: "40px" }}>
                <Spin size="large" className={`dark-spinner card-content-spinner`} />
            </div>
        );
    }
    else {
        return (
            <>
                <ResultsTopContainer name={userName} copyFlag={copyFlag} isMd03Member={isMd03Member} quizProducts={getAllProducts()} />
                {/* {
                    recommendations?.primary_recommendation && (
                        <QuizMD03 tags={recommendations?.primary_tags} productInfo={recommendations?.primary_recommendation[0]} />
                    )
                } */}
                <QuizProducts copyFlag={copyFlag} tags={getAllTags()} quizProducts={getAllProducts()} />
                <QuizCart />
            </>
        )
    }
}

export default QuizResult