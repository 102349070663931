import Footer from "./Global/Footer/Footer";
import Header from "./Global/Header/Header";
import RangeSlider from "./Global/RangeSlider/RangeSlider";
import HydrationRangeSlider from "./Global/HydrationRangeSlider/HydrationRangeSlider";
import FloatInput from "./Global/FloatInput/FloatInput";
import BetaFooter from "./Global/BetaFooter/BetaFooter";
import BetaHeader from "./Global/BetaHeader/BetaHeader";
import PhCard from "./Global/PhCard/PhCard";
import PhNavCard from "./Global/PhNavCard/PhNavCard";
import PhMediaCard from "./Global/PhMediaCard/PhMediaCard";
import RedirectToDashboard from "./RedirectionFromShopify/RedirectToDashboard";
import HandleToken from "./RedirectionFromShopify/HandleToken";
import Logout from "./RedirectionFromShopify/Logout";
import RedirectFromQRtoLogin from "./RedirectionFromShopify/RedirectFromQRtoLogin";
import RedirectToKitUserGuide from "./RedirectionFromShopify/RedirectToKitUserGuide";
import RedirectAfterSignUp from "./RedirectionFromShopify/RedirectAfterSignUp";
import RedirectionFromBetaShopify from "./RedirectionFromShopify/RedirectionFromBetaShopify";
import RedirectionFromBetaSignUp from "./RedirectionFromShopify/RedirectionFromBetaSignUp";
import QRLoginContainer from "./QR/QRLoginContainer";
import KitShowcase from "./KitShowcase/KitShowcase";
import Dashboard from "./Dashboard/Dashboard";
import HealthReport from "./HealthReport/HealthReport";
import Prescriptions from "./Prescriptions/Prescriptions";
import SkinCare from "./SkinCare/SkinCare";
import BackLInk from "./Global/BackLink/BackLInk";
import ProtocolHeader from "./Global/ProtocolHeader/ProtocolHeader"
import EmptyData from "./Global/EmptyData/EmptyData";
import PrescriptionsKitResult from "./PrescriptionsKitResult/PrescriptionsKitResult";
import ReportRow from "./Global/ReportRow/ReportRow";
import KitUserGuide from "./KitUserGuide/KitUserGuide";
import CrossSellBanner from "./CrossSellBanner/CrossSellBanner";
import KitStatus from "./KitStatus/KitStatus";
import KitResult from "./KitResult/KitResult";
import Report from "./Report/Report";
import SkinMicrobiomeSlider from "./Global/PHRangeSlider/SkinMicrobiomeSlider";
import SkinCareRecommendations from "./SkinCareRecommendations/SkinCareRecommendations";
import SuggestHealthKit from "./SuggestHealthKit/SuggestHealthKit";
import SuggestManyHealthKits from "./SuggestHealthKit/SuggetManyHealthKits";

// Quiz imports
import QuizHeader from "./Global/QuizHeader/QuizHeader";
import QuizProgressBar from "./Quiz/QuizProgessbar/index";
import Quiz from './Quiz/Quiz'
import QuizResult from "./Quiz/QuizResult/QuizResult";

import HealthProfileform from "./Jotform/HealthProfileform";
import CustomSelect from "./CustomSelect/CustomSelect";
import PHProtocol from "./PHProtocol/PHProtocol";
import MegaMenu from "./Global/MegaMenu/MegaMenu";
import PhButton from "./Global/PhButton/PhButton";
import MiniProtocol from "./PHProtocol/MiniProtocol/MiniProtocol";
import TwoColumnLayout from "./LayoutComponents/TwoColumnLayout";
import ContactDetailsForm from "./ContactDetailsForm/ContactDetailsForm";

export default {
    RedirectToDashboard,
    RedirectFromQRtoLogin,
    RedirectToKitUserGuide,
    RedirectAfterSignUp,
    RedirectionFromBetaShopify,
    RedirectionFromBetaSignUp,
    Footer,
    Header,
    BackLInk,
    EmptyData,
    RangeSlider,
    SkinMicrobiomeSlider,
    HandleToken,
    QRLoginContainer,
    Dashboard,
    HealthReport,
    Prescriptions,
    SkinCare,
    KitShowcase,
    Logout,
    KitUserGuide,
    CrossSellBanner,
    KitStatus,
    KitResult,
    PrescriptionsKitResult,
    ReportRow,
    Report,
    HydrationRangeSlider,
    FloatInput,
    BetaFooter,
    BetaHeader,
    PhCard,
    PhNavCard,
    PhMediaCard,
    SkinCareRecommendations,
    SuggestHealthKit,
    SuggestManyHealthKits,

    // Quiz imports declaration
    Quiz,
    QuizHeader,
    QuizResult,
    QuizProgressBar,
    HealthProfileform,
    CustomSelect,
    PHProtocol,
    ProtocolHeader,
    MegaMenu,
    PhButton,
    MiniProtocol,
    TwoColumnLayout,
    ContactDetailsForm
};
