import { Input, Grid } from 'antd'
import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import QuizStepOption from './QuizStepOption/index'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { scrollToContainer } from '@ph/common/global/Helpers'
import { useEffect } from 'react'
const { useBreakpoint } = Grid


const STRESS_OPTIONS = [
  { description: "Zen Master  🧘🏽" },
  { description: 'Smooth Sailing ⛵️' },
  { description: 'Busy Bee  🐝' },
  { description: 'Overworked Octopus 🐙' },
  { description: 'Vacation, Please! 🏖️' }
]
const MOBILE_STRESS_OPTIONS = [
  { description: 'Vacation, Please! 🏖️' },
  { description: 'Overworked Octopus 🐙' },
  { description: 'Busy Bee  🐝' },
  { description: 'Smooth Sailing ⛵️' },
  { description: "Zen Master  🧘🏽" },
]
// Validation schema
const validationSchema = Yup.object().shape({
  career: Yup.string().required('Career is required'),
  workStress: Yup.string().required('Work stress level is required'),
});

function PHQCareer() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_current_career);
  const careerAns = useSelector(state => state.quizData[queConstants.phq_current_career]);
  const workQue = getQuestionByQueId(queConstants.phq_workstress);
  const workAns = useSelector(state => state.quizData[queConstants.phq_workstress]);
  const screens = useBreakpoint();
  const isMobile = screens.xs

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      career: careerAns || '',
      workStress: workAns || '',
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.workStress) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.workStress])

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`} style={{ flexDirection: 'column' }}>
          <div className={`${style.questionWrapper} ${style.extraMargin}`}>
            <div className={`PHQQuestionText`}>{`What is your current career adventure?`} </div>
          <div style={{ maxWidth: '358px', margin: 'auto' }}>
            <Controller
              name="career"
              control={control}
              render={({ field }) => (
                <Input
                  className="PHQInputBottomLine"
                  {...field}
                  placeholder="job role/industry"
                  onChange={(e) => {
                    field.onChange(e); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_current_career, ans: e.target.value })); // Update Redux state
                  }}
                />
              )}
            />
              <p className="PHQErrorMessage">
                {errors.career && (
                  <span >{errors.career.message}</span>
                )}
              </p>
          </div>
        </div>
        <div className='PHQSpacer'></div>
        <div className={style.questionWrapper}>
          <div className='PHQQuestionText'>{`Rate your work stress level.`}</div>
          <Controller
            name="workStress"
            control={control}
            render={({ field }) => (
              <QuizStepOption
                options={isMobile ? MOBILE_STRESS_OPTIONS : STRESS_OPTIONS}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value); // Update form state
                  dispatch(updateQuizData({ queID: queConstants.phq_workstress, ans: value })); // Update Redux state
                }}
              />
            )}
          />
            <p className="PHQErrorMessage" id="errorMessage" >
              {errors.workStress && (
                <span >{errors.workStress.message}</span>
              )}
            </p>
        </div>
        </div>
      </div>

      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQCareer;
