import React, { useEffect, useState } from 'react'
import style from './ReportSections.module.scss'
import { Row, Col } from 'antd'
import PhButton from '../../Global/PhButton/PhButton'
import PHIncrementSelectButton from '../../Global/PHIncrementSelectButton/PHIncrementSelectButton'
import { useCart } from '@ph/common/context/CartContext'
import { createCartLink } from '@ph/common/global/Helpers'
import RecommendedText from '../ReportContainer/RecommendedText'
import { sortProducts } from '../reportUtils'
import ProductPrice from '../ReportContainer/ProductPrice'
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants'

function ProductSection({ reportData }) {

    const allProducts = sortProducts([...reportData?.serum_recommendations, ...reportData?.other_recommendations], reportData?.real_skin_age, reportData?.skin_age)
    const { products, reportPlan, md03Product } = useCart();

    const handleAddToCart = () => {
        const cartLink = createCartLink(process.env.REACT_APP_REPORT_SHOPIFY_DOMAIN, products, reportPlan, md03Product)
        window.location.href = cartLink;

    }


    return (
        <div className={style.productSection}>
            <div className={style.productHeader}>
                <Row>
                    <Col xs={1} sm={1} className={style.spacing}>
                        <div className='note-1 font-Mono-medium'>STEP</div>
                    </Col>
                    <Col xs={1} sm={5} className={style.spacing}>
                        <div className='note-1 font-Mono-medium'>PRODUCT</div>
                    </Col>
                    <Col xs={1} sm={8} className={style.spacing} >
                        <div className='note-1 font-Mono-medium'>DIRECTIONS</div>
                    </Col>
                    <Col xs={1} sm={9} className={`${style.dividedCol} ${style.spacing}`}>
                        <div className='note-1 font-Mono-medium'>AREA</div>
                    </Col>
                </Row>
            </div>
            {allProducts.map((product, index) => (
                <Product product={product} key={`${product.product_id}-${index}`} stepNo={index} reportPlan={reportPlan} />
            ))}
            <div className={style.buttonContainer}>
                <PhButton
                    type='primary'
                    text='Add Products To Cart To Proceed '
                    className={style.addButton}
                    onClick={handleAddToCart}
                    disabled={products?.length === 0}
                />
            </div>
            <div className={`${style.thankyouContainer} show-desktop-hide-mobile`}>
                <div>
                    <span className={style.retestText}>Retest in 6 months</span>
                </div>
                <div>
                    <span className={style.thankyouText}>Thank You!</span>
                </div>
            </div>
        </div>
    )
}

export default ProductSection

const Product = ({ product, stepNo, reportPlan,
    discountBg = '#2D2926',
    discountTextColor = '#FFFFFF',
    discountTextHover = '#FFFFFF',
    discountBgHover = '#2D2926',
}) => {

    const styles = {
        '--discount-bg': discountBg,
        '--discount-bg-hover': discountBgHover,
        '--discount-text': discountTextColor,
        '--discount-text-hover': discountTextHover
    }

    const [productQuantity, setProductQuantity] = useState(0)
    const { products, addProduct, removeProduct, updateQuantity } = useCart();

    useEffect(() => {
        const isPresent = products.filter(currProduct => currProduct.product_id === product.product_id)
        if (isPresent && isPresent.length > 0) {
            setProductQuantity(isPresent[0].quantity)
        } else {
            setProductQuantity(0)
        }
    }, [products, productQuantity])

    const handleOnMinusClick = () => {
        setProductQuantity(productQuantity - 1)
        updateQuantity(product.product_id, productQuantity - 1);
    }

    const handleOnPlusClick = () => {
        setProductQuantity(productQuantity + 1)
        updateQuantity(product.product_id, productQuantity + 1);

    }

    const handleProductCheckbox = () => {
        if (productQuantity) {
            setProductQuantity(0)
            removeProduct(product.product_id)
        } else {
            setProductQuantity(1)
            addProduct({ ...product, quantity: 1 });
        }
    }

    const SKIN_SITE_LABELS = {
        CK: 'CHEEK',
        CN: 'CHIN',
        TZ: 'T-ZONE'
    }

    const getSkinSiteLabel = () => {
        if (product?.skin_site) {
            if (SKIN_SITE_LABELS[product?.skin_site]) {
                return SKIN_SITE_LABELS[product?.skin_site]
            }
            else {
                return 'All over face'
            }
        }
        return ''
    }
    const discountPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] : null;
    const originalPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] : null;

    return (
        <div className={`${style.productRow} ${product?.price ? '' : style.overlay}`} style={styles}>
            <Row className={style.productDetails}>
                <Col xs={24} md={24} xl={1}>
                    <div className={`${style.spacing} center-flex`}>
                        <div className='show-mobile-hide-desktop '>
                            <span className='note-2 font-Mono-medium' style={{ marginRight: '8px' }}>STEP</span>
                        </div>
                        <div className='h4'>{('0' + Number(stepNo + 1)).slice(-2)}</div>
                    </div>
                </Col>
                <Col xs={24} md={24} xl={5} className="show-desktop-hide-mobile">
                    <div className={`${style.spacing} h6`}>{product?.name}</div>
                </Col>
                <Col xs={24} md={24} xl={8} className="show-desktop-hide-mobile" style={{ flexDirection: 'column' }}>
                    {product?.is_included && (
                        <div style={{ paddingBottom: '8px', width: '100%' }} className={style.spacing} >
                            <RecommendedText />
                        </div>
                    )}
                    {/* <div className='note-2 font-Mono-medium'>{product?.directions.replaceAll('\n\n', '<br/>')}</div> */}
                    <div className={`${style.spacing} ${style.directionText}`} dangerouslySetInnerHTML={{ __html: product?.directions?.replaceAll('\n\n', '<br/>') }}></div>

                </Col>
                <Col xs={14} md={19} xl={0} style={{ padding: '0px', alignItems: 'baseline' }} className="show-mobile-hide-desktop">
                    <Row className={style.productDetails}>
                        <Col xs={24} xl={5}>
                            <div className='h5'>{product?.name}</div>
                        </Col>
                        {product?.price && (
                            <Col xs={24}>
                                <ProductPrice originalPrice={originalPrice} discountPrice={discountPrice} reportPlan={reportPlan} showCompact={false} />
                            </Col>
                        )}

                        <Col xs={0} xl={8} className={style.hideMobile}>
                            <div className={style.directionText} dangerouslySetInnerHTML={{ __html: product?.directions?.replaceAll('\n\n', '<br/>') }}></div>
                        </Col>
                        <Col xs={24}>
                            <div className='note-1 font-Mono-medium'>{getSkinSiteLabel() ? `AREA - ${getSkinSiteLabel()}` : ''}</div>
                        </Col>
                    </Row>
                </Col>


                <Col xs={10} md={5} xl={0} style={{ height: '100%', flexDirection: 'column', gap: '20px' }} className="show-mobile-hide-desktop">
                    <div className={style.productImage}>
                        <img src={product?.image_url} />
                    </div>
                </Col>
                <Col xs={24} xl={10} className={`${style.dividedCol} show-desktop-hide-mobile`}>
                    <Row className={style.imageSection}>
                        <Col xs={24} xl={7} style={{ height: '100%' }}>
                            <div className={`${style.spacing} note-1 font-Mono-medium`}>{getSkinSiteLabel()}</div>
                        </Col>
                        <Col xs={8} xl={7} className={`${style.productImage}`} style={{ height: '100%' }}>
                            <img src={product?.image_url} />
                        </Col>
                        <Col xs={24} xl={10} >
                            {product?.price && (
                                <ProductPrice originalPrice={originalPrice} discountPrice={discountPrice} reportPlan={reportPlan} />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={0} xl={0} className={style.directionContainer}>
                    <Col xs={24} md={0} xl={8} >
                        <div className={style.directionText} dangerouslySetInnerHTML={{ __html: product?.directions?.replaceAll('\n\n', '<br/>') }}></div>
                    </Col>
                </Col>
                {product?.is_included && (
                    <Col xs={24} className={`${style.dividedCol} show-mobile-hide-desktop`}>
                        <RecommendedText />
                    </Col>
                )}


                {product?.price && (
                    <PHIncrementSelectButton
                        productQuantity={productQuantity}
                        handleOnMinusClick={handleOnMinusClick}
                        handleOnPlusClick={handleOnPlusClick}
                        handleCheckbox={handleProductCheckbox}
                        included={product?.is_included}
                    />
                )}


            </Row>
        </div>
    )
}