import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import QuizSlider from '../QuizSlider/QuizSlider'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'
import AreaButtonWrapper from '../AreaButton/AreaButtonWrapper'
import AreaButton from '../AreaButton/AreaButton'

const NOT_KNOWN_BUTTON_CONFIG = {
  key: 'not know',
  text: `I DON’T USE A CLEANSER`,
  backgroundColor: '#2E5ACC',
  color: '#FFFFFF',
}

// Validation schema
const validationSchema = Yup.object().shape({
  cleanserGentleness: Yup.number().min(0, `Please select a value on the scale or click on I don't user cleanser`).max(10).required('Please rate the gentleness of your cleanser'), // Ensure a value is selected
});

function PHQSkinCleanser() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_cleanser_gentleness);
  const cleanserGentlenessAns = useSelector(state => state.quizData[queConstants.phq_cleanser_gentleness]);

  const { reset, control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cleanserGentleness: cleanserGentlenessAns || 0, // Default to existing answer or 0
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.cleanserGentleness) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.cleanserGentleness])

  const handleNotKnownButton = () => {
    dispatch(updateQuizData({ queID: queConstants.phq_cleanser_gentleness, ans: 0 }))
    reset({ cleanserGentleness: 0 })
  }

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>
              If you’re currently using a cleanser, how does it fare on the gentleness scale?

            </div>
            <div className='PHQMessage'>■ click on the scale below <br />
              CLICK "I DON'T USE A CLEANSER" IF YOU DO NOT CURRENTLY USE A CLEANSER</div>
            <div className='PHQSpacer'></div>
            <Controller
              name="cleanserGentleness"
              control={control}
              render={({ field }) => (
                <QuizSlider
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_cleanser_gentleness, ans: value })); // Update Redux state
                  }}
                  leftText='It’s like a sandpaper hug -- it might be time for a gentler companion!'
                  rightText='It’s like a cozy, soft blanket for my face—pure bliss!'
                  domId={'cleanser'}
                  gradient='linear-gradient(90deg, #EACDFF 0%, #EAFFA6 78%)'
                />
              )}
            />
            <div className='sliderButton'>
              <AreaButton
                key={NOT_KNOWN_BUTTON_CONFIG.key} // Use a unique key for each button
                area={NOT_KNOWN_BUTTON_CONFIG.key}
                isSelected={cleanserGentlenessAns === 0}
                onClick={() => handleNotKnownButton()}
                backgroundColor={NOT_KNOWN_BUTTON_CONFIG.backgroundColor}
                color={NOT_KNOWN_BUTTON_CONFIG.color}
              >
                {NOT_KNOWN_BUTTON_CONFIG.text}
              </AreaButton>
            </div>
            <p className="PHQErrorMessage sliderError" id="errorMessage">
              {errors?.cleanserGentleness && (
                <span>{errors?.cleanserGentleness.message}<br /></span>
              )}
            </p>
          </div>
        </div>
      </div>

      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQSkinCleanser;