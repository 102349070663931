export const queConstants = {
    'phq_name': 'name',
    'phq_email': 'email',
    'phq_age': 'age',
    'phq_zipcode': 'zipcode',
    'phq_gender': 'gender',
    'phq_ethnicity': 'ethnicity',
    'phq_hallmarks': 'hallmarks',
    'phq_face_issues': 'face_skin_issues',
    'phq_body_issues': 'body_issues',
    'phq_face_area': 'face_blemish_area',
    'phq_current_career': 'career',
    'phq_workstress': 'stress',
    'phq_body_blemish_area': 'body_blemish_area',
    'phq_body_odor_area': 'body_odor_area',
    'phq_cleanser_gentleness': 'cleanser_gentleness',
    'phq_skin_moisturizer': 'moisturizer_protectivity',
    'phq_doctor_skincare': 'prescription',
    'phq_skincare_adventure': 'skincare_adventure',
    'phq_aging_process': 'aging_guidance',
    'phq_skin_pamper': 'skin_pamper',
    'phq_skincare_curiosity': 'skincare_curiosity',
    "phq_most_intrested_in": 'skin_interested_in',
    'phq_parallel': 'parallel',
    "phq_anti_aging_products": 'anti_aging_products_treatments',
    "phq_when_it_comes_to_parallel": 'recommandations_type',
}

export const COPY_FLAGS = {
    MD03: 'MD03',
    PART_REC: 'PARTIAL RECOMMENDATIONS',
    NO_REC: 'NO RECOMMENDATIONS'
}

export const QUIZ_PAGE_TEXT = {
    [COPY_FLAGS.MD03]: {
        welcomeText: <>Based on your quiz results, we've curated some personalized recommendations tailored to your skin concerns and goals. We have your recommended <span className="font-Neue-medium">MD-03 Protocol™</span> to best help you achieve your skin health goals. Explore additional product suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you are facing',
        showMd03: true
    },
    [COPY_FLAGS.PART_REC]: {
        welcomeText: <>Based on your quiz results, you indicated that you currently don't experience any active skin or odor issues on your face and body, but you are concern about skin aging. We recommend the following products to help you address your skin aging concerns to and maintain healthy skin. Explore the suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you are facing',
        showMd03: false
    },
    [COPY_FLAGS.NO_REC]: {
        welcomeText: <>Based on your quiz results, you indicated that you currently don't experience any concerning skin or odor issues on your face and body. We recommend the following products to help you maintain healthy skin. Explore the suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you have no issues, we are recommending for',
        showMd03: false
    }
}

export const QUIZ_PLAN_MODAL_TYPES = {
    WELCOME: 'WELCOME',
    DOWNGRADE: 'DOWNGRADE'
}

export const QUIZ_PLAN_MODAL_DETAILS = {
    [QUIZ_PLAN_MODAL_TYPES.WELCOME]: {
        title: 'Welcome!',
        subTitle: <><span className="font-Neue-medium">You're upgrading to the MD-03 Protocol™.</span> <br />Enjoy exclusive discounted pricing, 24/7 access to Microbiome Dermatology™ doctors, and skin microbiome testing twice a year.</>,
        cancelText: '',
        successText: ''
    },
    [QUIZ_PLAN_MODAL_TYPES.DOWNGRADE]: {
        title: 'Oh No!',
        subTitle: <>By removing your MD-03 Protocol™, you will lose exclusive discounting, 24/7 access to our physicians, and free testing twice a year.</>,
        cancelText: 'Remove',
        successText: 'Keep MD03 Protocol™'
    }
}


export function getProductLabel(productName) {
    switch (productName.toLowerCase()) {
        case "skin discovery test":
            return "Since you reported that on your face, you are experiencing"
        case "body blemish test + serum":
            return "Since you reported that on your body, you are experiencing";
        case "odor discovery test + serum":
            return "Since you reported that you are facing";
        case "body blemish test":
            return "Since you reported that on your body, you are experiencing";
        case "odor discovery test":
            return "Since you reported that you are facing";
        case "personalized prescription":
            return "Because you reported you are experiencing a concern and/or are open to";
        case "blue biotic™ multi-effect peptide cream":
            return "Based on your skin aging goals and priorities, we recommend you focus on";
        default:
            return 'Since you reported that you are facing';
    }
}

export function getProductDescription(product) {
    switch (product?.product_name?.toLowerCase()) {
        case "skin discovery test":
            return "We all experience skin issues on our faces, but since you’ve indicated that it bothers you, we recommend the Skin Discovery Test for your face. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the skin issue(s) on your face, allowing us to provide targeted solutions to address it."
        case "body blemish test + serum":
            return "We all experience body blemish, but since you’ve indicated that it bothers you, we recommend the Body Blemish Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the blemish, allowing us to provide targeted solutions to address it.";
        case "body blemish test":
            return "We all experience body blemish, but since you’ve indicated that it bothers you, we recommend the Body Blemish Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the blemish, allowing us to provide targeted solutions to address it.";
        case "odor discovery test + serum":
            return "We all experience body odor, but since you’ve indicated that it bothers you, we recommend the Body Odor Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the odor, allowing us to provide targeted solutions to address it.";
        case "odor discovery test":
            return "We all experience body odor, but since you’ve indicated that it bothers you, we recommend the Body Odor Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the odor, allowing us to provide targeted solutions to address it.";
        default:
            return product?.description?.short_text
    }
}

export const MD03_LABEL = {
    "Skin Discovery Test": "Facial",
    "Body Blemish Test": "Blemish",
    "Odor Discovery Test": "Odor",
    "Body Blemish Test + Serum": "Blemish",
    "Odor Discovery Test + Serum": "Odor"
}

export const DO_NOT_KNOW = `I don't know`
// export const QUIZ_PLAN_DETAILS = {
//     [QUIZ_PLANS.MD03]: {
//         cart_title: <><span className="font-Mono-bold">CONGRATULATIONS!</span> As a member of MD-03, you exclusively access up to 51% OFF all add-on products</>,
//         cart_addon_title: 'PLAN ADD-ONS',
//         show_product_discount: true,
//         discount_suggestion_text_short: null,
//         discount_suggestion_text_large: null,
//         product_price_key: 'md_03_price',
//         original_price_key: 'md_03_price',
//         discount_price_key: 'price',
//         product_variant_key: 'md_03_variant_id',
//         product_selling_plan_key: 'md_03_selling_plan_id',
//         serum_plan_title: <> as a member of <span className="font-Mono-bold">MD-03</span>, you exclusively access up to< span className="font-Mono-bold" > 51% off </span >all add-on products.</>,
//         skin_regimen_title: 'As a member of MD-03, you exclusively access up to 51% OFF all add-on products',
//         skin_regimen_subtitle: '',
//         welcome_text: 'We are happy to present your MD03 plan Skin Health Report'
//     },
//     [QUIZ_PLANS.TESTER]: {
//         cart_title: <>Sign up for the MD-03 Protocol™ by <span className="font-Mono-bold">adding a phage serum </span> to get exclusive discounted pricing on all products across the report</>,
//         cart_addon_title: 'ADD-ONS',
//         show_product_discount: true,
//         product_price_key: 'price',
//         original_price_key: 'ala_carte_discount_price',
//         discount_price_key: 'price',
//         product_variant_key: 'variant_id',
//         product_selling_plan_key: 'selling_plan_id',
//         discount_suggestion_text_short: 'ADD ANY SERUM ABOVE TO ACCESS EXCLUSIVE DISCOUNTED PRICING',
//         discount_suggestion_text_large: 'SIGN UP FOR THE MD-03 PROTOCOL™ BY ADDING A PHAGE SERUM ABOVE TO GET EXCLUSIVE DISCOUNTED PRICING ON ALL PRODUCTS ACROSS THE REPORT',
//         serum_plan_title: <>Purchase any serum below for <span className="font-Mono-bold">$195/mo</span> to upgrade to <span className="font-Mono-bold">the MD-03™ Protocol</span> and unlock concierge care and exclusive discounts. Get your second serum and beyond for <span className="font-Mono-bold">$95/mo</span>.</>,
//         skin_regimen_title: 'Purchase any serum below to upgrade to the MD-03 Protocol™ and unlock exclusive low pricing, two tests a year, and 24/7 access to medical guidance',
//         skin_regimen_subtitle: '',
//         welcome_text: 'We are happy to present your Skin Health Report'
//     }
// }
