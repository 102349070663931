import { Input, Button } from 'antd'
import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import QuizStepOption from './QuizStepOption/index'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import ButtonOptions from './QuiZButtonOption/index'
import QuizHallmarks from '../QuizHallmarks/QuizHallmarks'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { scrollToContainer } from '@ph/common/global/Helpers'
import { useEffect } from 'react'

// Validation schema
const validationSchema = Yup.object().shape({
  faceIssues: Yup.array().min(1, 'At least one face issue must be selected'), // Ensure at least one issue is selected
});

function PHQFaceIssues() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_face_issues);
  const faceIssuesAns = useSelector(state => state.quizData[queConstants.phq_face_issues]);

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      faceIssues: faceIssuesAns || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.faceIssues) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.faceIssues])

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
        <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>
              Our skin tells a unique story, and skin issues play a crucial role. <br className='show-desktop-hide-mobile' />
              Tell us about any skin issues you're managing on your <span className='font-Neue-medium'>face</span>.
            </div>
          <div className='PHQMessage'>■ choose all that apply</div>
          <div className='PHQSpacer'></div>
            <div className={style.bigButtonOption}>
            <Controller
              name="faceIssues"
              control={control}
              render={({ field }) => (
                <ButtonOptions
                  question={`What are your current skin concerns?`}
                  options={[
                    'Fungal Acne',
                    'Cystic/Hormonal Acne',
                    'Eczema/Dry Skin',
                    'Rosacea/Redness',
                    'Melasma/Dark Spots',
                    'Seborrheic Dermatitis',
                    'Hidradenitis Suppurativa',
                  ]}
                  values={field.value}
                  onChange={(value) => {
                    field.onChange(value); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_face_issues, ans: value })); // Update Redux state
                  }}
                  otherAllowed={true}
                />
              )}
            />
            </div>
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.faceIssues && (
                <span >{errors.faceIssues.message}</span>
              )}
            </p>
            <div className='PHQSpacer show-mobile-hide-desktop'></div>
          </div>
        </div>
      </div>

      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQFaceIssues;
