import React, { useState, useEffect, useMemo } from 'react';

import country from "@ph/common/constants/country.json"
import QuizSelect from '../Questions/QuizSelect/index';
import style from '../Questions/questions.module.scss'
import { Input } from 'antd'

// Import the JSON data directly
const rawCountriesData = country.countries


const USA_COUNTRY_NAME = "🇺🇸 United States"

const ZIP_CODE_COUNTRIES = ["🇺🇸 United States", "🇨🇦 Canada"]


const CountryZipcodeValidator = ({ value, onChange }) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [validationResult, setValidationResult] = useState('');
    const [city, setCity] = useState('')

    const countriesData = useMemo(() => {
        return [...rawCountriesData].sort((a, b) => a.name.localeCompare(b.name));
    }, []);

    const validateZipcode = (selectedCountry, zipcode) => {
        if (!selectedCountry || !zipcode) {
            setValidationResult('');
            return;
        }

        const country = countriesData.find(c => `${c.flag} ${c.name}` === selectedCountry);
        if (!country) {
            setValidationResult('Invalid country selection.');
            return;
        }

        const regex = new RegExp(country.pattern);
        const isValid = regex.test(zipcode);
        setValidationResult(isValid ? '' : 'Invalid zipcode.');
        return isValid ? '' : 'Invalid zipcode.'
    };

    const handleCountryChange = (value) => {
        setSelectedCountry(value);

        if (!value) {
            onChange('')
        }
        if (ZIP_CODE_COUNTRIES.includes(value)) {
            if (zipcode && !validateZipcode(value, zipcode)) {
                onChange(`${value}:${zipcode}`)
            } else {
                onChange('')
            }
        } else {
            if (value && city) {
                onChange(`${value}:${city}`)
            } else {
                onChange('')
            }
        }
    };

    const handleZipcodeChange = (event) => {
        const zipcodeValue = event.target.value?.trim()
        setZipcode(zipcodeValue);

        if (!zipcodeValue) {
            onChange('')
        }
        if (ZIP_CODE_COUNTRIES.includes(selectedCountry)) {
            if (zipcodeValue && !validateZipcode(selectedCountry, zipcodeValue)) {
                onChange(`${selectedCountry}:${zipcodeValue}`)
            } else {
                onChange('')
            }
        }
    };

    const handleCityChange = (event) => {
        const cityValue = event.target.value?.trim()
        setCity(cityValue);

        // Empty value when city is empty
        if (selectedCountry && cityValue) {
            onChange(`${selectedCountry}:${cityValue}`)
        } else {
            onChange('')
        }
    };

    const handleZipcodeKeyPress = (event) => {
        if (event.key === 'Enter') {
            validateZipcode(selectedCountry, zipcode);
        }
    };

    useEffect(() => {
        if (value) {
            const newValue = value.split(":")
            if (ZIP_CODE_COUNTRIES.includes(newValue[0])) {
                setSelectedCountry(newValue[0])
                setZipcode(newValue[1])
            } else {
                setSelectedCountry(newValue[0])
                setCity(newValue[1])
            }
        }
    }, [])

    return (
        <>
            <span className='PHQQuestionText'>and I live in </span>
            <span className={style.selectWrapper}>
                <QuizSelect
                    id="country-select"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    options={countriesData.map(country => `${country.flag} ${country.name}`)}
                    popupClassName={style.leftSelect}
                    placeholder={"select country"}
                >
                </QuizSelect>
            </span>

            <label htmlFor="zipcode-input" className='PHQQuestionText'>at </label>
            {ZIP_CODE_COUNTRIES.includes(selectedCountry) ? (
                <Input
                    id="zipcode-input"
                    className="PHQInputBottomLine"
                    type="text"
                    value={zipcode}
                    onChange={handleZipcodeChange}
                    onBlur={validateZipcode}
                    onKeyPress={handleZipcodeKeyPress}
                    placeholder="zipcode"
                    style={{ display: 'inline-block', maxWidth: '181px' }}
                />
            ) : (
                <Input
                    id="city-input"
                    className="PHQInputBottomLine"
                    type="text"
                    value={city}
                    onChange={handleCityChange}
                    placeholder="city"
                    style={{ display: 'inline-block', maxWidth: '181px' }}
                />
            )}

            {/* <p className="PHQErrorMessage">
                {validationResult && <span>{validationResult}</span>}
            </p> */}
        </>
    );
};

export default CountryZipcodeValidator;
