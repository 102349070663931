import React, {useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import {LAB_PORTAL_SIDE_NAV, LAB_REPORT_ROOT_NAV} from '../../../utils/nav-menu-items';
import style from './sidebar.module.scss';
import {convertToURIPath} from '@ph/common/global/Helpers';
import {getRecordsCount} from "../../AdminLabPortal/AdminPortalAPIService";

const {Sider} = Layout;

const LabPortalSidebar = ({ refreshSideBarCount, setRefreshSideBarCount, currNav, setCurrentNav }) => {
    const currentLocation = useLocation();
    const pathName = currentLocation.pathname.replace("/", "");
    let navigate = useNavigate();
    const [menuItemsList, setMenuItemsList] = useState([]);
    const [sideNavItemsList, setSideNavItemsList] = useState(Object.entries(LAB_PORTAL_SIDE_NAV));

    const getItem = (label, key, children) => {
        return {
            key,
            label,
            children
        };
    }

    const subMenuNavItemBuilder = ({name, count, key}) => {
        if (key === currNav) {
            localStorage.setItem('currentNavLabel', name);
        }
        return <div className='clearfix'>
            <span className='fl'>{name}</span>
            <span className='fr'>{count}</span>
        </div>
    };

    useEffect(() => {
        getRecordsCount().then(recordsCountSuccessHandler).catch((error) => {
            console.log(error);
        }).finally(() => {
            setMenuItemsList([]);
            sideNavItemsList.forEach(([rootItem, navItem]) => {
                let subMenuItems = navItem.map(subMenuItem => {
                    subMenuItem.key = convertToURIPath(subMenuItem.name, 'lab-portal');
                    return getItem(subMenuNavItemBuilder(subMenuItem), subMenuItem.key);
                });

                const newItems = subMenuItems.length > 0 ? getItem(rootItem, rootItem, subMenuItems) : getItem(rootItem, rootItem)
                const newList = [newItems, { type: 'divider' }]
                setMenuItemsList(prevState => [...prevState, ...newList]);
            });
        });
    }, [refreshSideBarCount]);

    function recordsCountSuccessHandler(result) {
        if (!result || !result.data) {
            return;
        }
        const {data} = result;
        setSideNavItemsList(prevState => {
            prevState.forEach(([rootItem, navItem]) => {
                navItem.forEach((navObj) => {
                    navObj.count = data[`${navObj.id}_count`] || 0;
                })
            })
            return [...prevState];
        })
    }

    // function handleClick({key, keyPath}) {
    //     if (keyPath && keyPath.length > 1 && LAB_PORTAL_SIDE_NAV[keyPath[1]]) {
    //         let menuItem = LAB_PORTAL_SIDE_NAV[keyPath[1]].find((el) => el.key === key) || {};
    //         localStorage.setItem('currentNavLabel', menuItem.name);
    //     }
    //     setCurrentNav(key);
    //     navigate(`/${convertToURIPath(key)}`);
    //     setRefreshSideBarCount(!refreshSideBarCount);
    // }

    function handleClick({ key, keyPath, ...otherProps }) {
        if (keyPath && keyPath.length > 1 && LAB_PORTAL_SIDE_NAV[keyPath[1]]) {
            let menuItem = LAB_PORTAL_SIDE_NAV[keyPath[1]].find((el) =>
                convertToURIPath(el.name, 'lab-portal') === key) || {};
            localStorage.setItem('currentNavLabel', menuItem.name);
        }
        if (keyPath && keyPath.length === 1 && keyPath[0]) {
            localStorage.setItem('currentNavLabel', keyPath[0]);
            navigate(`/${convertToURIPath(keyPath[0], 'lab-portal')}`);
        } else {
            navigate(`/${convertToURIPath(key)}`);
        }
        setCurrentNav(key);
        setRefreshSideBarCount(!refreshSideBarCount);
    }

    return <Sider width={260} className={style.portalSidebar}>
        <Menu
            mode="inline"
            defaultSelectedKeys={[pathName]}
            defaultOpenKeys={LAB_REPORT_ROOT_NAV}
            onClick={handleClick}
            selectedKeys={[currNav]}
            multiple={false}
            className="sidebar"
            items={menuItemsList}
        />
    </Sider>;
};

export default LabPortalSidebar;
